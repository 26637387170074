import { createAction } from "@reduxjs/toolkit";

import { IContext, IPermission, PermissionsActions } from "./permissions.types";

export const signupContextAction = createAction<IContext>(
  PermissionsActions.CONTEXT_SIGNUP
);

export const registerPermissionAction = createAction<IPermission>(
  PermissionsActions.PERMISSION_REGISTER
);
