import React, { Suspense } from "react";
import type { IModuleConfiguration, OrbitRoute } from "types";

const NotFoundLazy = React.lazy(() => import("../containers/notFound"));

const notFoundRoutes: OrbitRoute[] = [
  {
    path: "*",
    name: "Not found",
    element: (
      <Suspense fallback={<div>Loading not found...</div>}>
        <NotFoundLazy />
      </Suspense>
    ),
  },
];

export const ModuleConfiguration: IModuleConfiguration = {
  name: "Not found",
  navigation: {
    nameTranslationKey: "Not found",
    hideFromNavigation: true,
  },
  routes: notFoundRoutes,
};

export default ModuleConfiguration;
