import { StyleTokens } from "style.tokens";
import styled, { css } from "styled-components";

interface IOrbitDivider {
  color?: string;
  margins?: number | [number, number] | [number, number, number, number];
  size?: number | string;
  width?: number;
}

export const OrbitDivider = styled.div<IOrbitDivider>`
  height: ${(props) => (props.width ? `${props.width}px` : "1px")};
  background-color: ${(props) => props.color || StyleTokens.layoutBodyBackground};
  margin: ${(props) => {
    if (!props.margins) {
      return "8px 0px 4px 0px";
    }
    if (!Array.isArray(props.margins)) {
      return `${props.margins}px`;
    }
    return props.margins.join("px ") + "px";
  }};
  ${(props) => {
    if (props.size) {
      if (typeof props.size === "number") {
        return css`
          width: ${props.size}px;
        `;
      }
      return css`
        width: ${props.size};
      `;
    }
  }}
`;
