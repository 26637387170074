import { PayloadAction } from "@reduxjs/toolkit";

export const AuthRoutes = {
  login: {
    path: "login",
    name: "Login",
  },
};

export enum StoredItems {
  ACCESS_TOKEN = "a",
  REFRESH_TOKEN = "r",
  ACCESS_TOKEN_EXPIRATION_DATE = "e",
}

export interface ICompany {
  companyId: string;
  locations: number[];
}

export interface IRelatedCompanies {
  asSpaceProvider: ICompany[];
  asCustomer: ICompany[];
}

export enum Actions {
  UPDATE_RELATED_COMPANIES = "UPDATE_RELATED_COMPANIES",
}

export interface IAuthStore {
  relatedCompanies: IRelatedCompanies;
}

/**
 * 🔽 Action Types
 */
export type CreateContextAction = PayloadAction<
  IRelatedCompanies,
  Actions.UPDATE_RELATED_COMPANIES
>;
/**
 * 🔼 Action Types
 */
