import { LanguageSelectorPure } from "components/language-selector-pure/LanguageSelectorPure";
import { eEvents, eLanguages, Languages } from "config";
import i18n from "i18n";
import { useAnalytics } from "modules/analytics/shared/hooks/useAnalytics";

interface ILanguageSelectorProps {
  className?: string;
  onChange?: (newLang: string) => void;
}

export const LanguageSelector = (props: ILanguageSelectorProps) => {
  const { onChange, ...other } = props;
  const analytics = useAnalytics();
  return (
    <LanguageSelectorPure
      {...other}
      currentLanguage={Languages[i18n.language as eLanguages]}
      languages={Object.values(Languages)}
      onChange={(lang) => {
        i18n.changeLanguage(lang);
        onChange && onChange(lang);
        analytics?.track(eEvents.LANGUAGE_SELECTION, {
          language: lang.toUpperCase(),
        });
      }}
    />
  );
};
