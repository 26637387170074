import { eLanguages, ePackages, TranslationsPackages } from "config";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Pseudo from "i18next-pseudo";
import { initReactI18next } from "react-i18next";
import { enumKeys, EnumToValuesArray, getEnumValues } from "utils/enumUtils";
import { isDebug } from "utils/isDebug";

export const defaultNS = "generic";

const translationPackagesArray: {
  packageName: string;
  fileRelativePath: string;
}[] = enumKeys(ePackages).map((packageName) => ({
  packageName: ePackages[packageName],
  fileRelativePath: TranslationsPackages[ePackages[packageName] as ePackages],
}));

export const getTranslationsPackagesWithLang = (lang: string) => {
  return translationPackagesArray.reduce((obj, item) => {
    return {
      ...obj,
      [item.packageName]: require(`l10n/${lang}/${item.fileRelativePath}`),
    };
  }, {});
};

export const finalResourcesObject = enumKeys(eLanguages).reduce((obj, lang) => {
  return {
    ...obj,
    [eLanguages[lang]]: {
      ...getTranslationsPackagesWithLang(eLanguages[lang]),
    },
  };
}, {});

i18n
  .use(
    new Pseudo({
      enabled: process.env.NODE_ENV === "development",
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS,
    ns: EnumToValuesArray(ePackages),
    resources: finalResourcesObject,
    fallbackLng: eLanguages.EN,
    supportedLngs: getEnumValues(eLanguages),
    debug: isDebug(),
    postProcess: ["pseudo"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnEmptyString: false,
    returnNull: false,
    returnObjects: false,
  });

export default i18n;
