import { _CloseButton } from "./buttons/CloseButton";
import { _OrbitButton, IOrbitButtonProps } from "./OrbitButton";

type CompoundedComponent = React.ForwardRefExoticComponent<IOrbitButtonProps> & {
  Close: typeof _CloseButton;
};

const BaseOrbitButton = _OrbitButton as CompoundedComponent;

BaseOrbitButton.Close = _CloseButton;

export { BaseOrbitButton as OrbitButton };
