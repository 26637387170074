import type { IMenuStore, ItemRegisterAction } from "../shared/menu.types";

export const ItemRegisterReducer = (
  state: IMenuStore,
  action: ItemRegisterAction
) => {
  if (state.items.find((item) => item.key === action.payload.key) === undefined) {
    state.items = [...state.items, action.payload];
  }
  return state;
};
