import { createSlice } from "@reduxjs/toolkit";

import { registerPermissionReducer } from "../reducers/registerPermission.reducer";
import { signupContextReducer } from "../reducers/signupContext.reducer";
import {
  registerPermissionAction,
  signupContextAction,
} from "./permissions.actions";
import type { IPermissionsStore } from "./permissions.types";

const initialState: () => IPermissionsStore = () => {
  return {
    permissions: [],
    contexts: [],
  };
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: {
    [signupContextAction.type]: signupContextReducer,
    [registerPermissionAction.type]: registerPermissionReducer,
  },
});

export const permissionsReducer = permissionsSlice.reducer;
