import { createSlice } from "@reduxjs/toolkit";

import { UpdateRelatedCompaniesReducer } from "../reducers/updateRelatedCompanies.reducer";
import { updateRelatedCompaniesAction } from "./auth.actions";
import { IAuthStore } from "./auth.types";

const initialState: () => IAuthStore = () => {
  return {
    relatedCompanies: {
      asSpaceProvider: [],
      asCustomer: [],
    },
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [updateRelatedCompaniesAction.type]: UpdateRelatedCompaniesReducer,
  },
});

export const authReducer = authSlice.reducer;
