import { ProtectedRoute } from "components/private-route/PrivateRoute";
import { isUserLoggedIn } from "modules/auth/shared/utils/isUserLoggedIn";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router";

export const AuthorizedRoute = (props: PropsWithChildren<{}>) => {
  const location = useLocation();

  return (
    <ProtectedRoute
      redirectPath={`/login?redirect=${location.pathname}`}
      shouldRedirect={() => !isUserLoggedIn()}
      children={props.children}
    />
  );
};
