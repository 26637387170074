import { AnalyticsContext } from "modules/analytics/shared/contexts/AnalyticsContext";
import { useContext } from "react";

import { AnalyticsMock } from "../analytics.types";

export const useAnalytics = () => {
  const { instance } = useContext(AnalyticsContext);
  if (process.env.REACT_APP_SEGMENT_ENABLED === "true" && !instance)
    console.error("Analytics instance undefined");
  return process.env.REACT_APP_SEGMENT_ENABLED === "true" ? instance : AnalyticsMock;
};
