export interface ILocation {
  trackingKey?: string;
  trackingId?: number;
  label: string;
  value: string;
}

export const AllLocationsValue = "all";

export const AllLocationsObject: ILocation = {
  trackingKey: "All Locations",
  trackingId: 0,
  label: "overview:locationFilter.allLocations",
  value: AllLocationsValue,
};
