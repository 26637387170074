import { PayloadAction } from "@reduxjs/toolkit";

export interface IPermissionsStore {
  permissions: IPermission[];
  contexts: IContext[];
}

/**
 * Start Enums
 */
export enum PermissionsActions {
  PERMISSION_REGISTER = "permission/register",
  CONTEXT_SIGNUP = "context/signup",
}
export enum eVerifyFunctions {
  SYSTEM = "system",
  NUMBER = "number",
}

export enum eDomainScopes {
  ALL = "all",
  SOME = "some",
}

export enum eContexts {
  SYSTEM = "system",
  LOCATION = "location",
  COMPANY = "company",
}
export enum eActions {
  PERFORM = "perform",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
  ALL = "all",
  CREATE = "create",
}
export enum ePermissions {
  OVERVIEW_INSIGHTS = "overviewInsights",
  USERS = "users",
  INVITATIONS = "invitations",
  USER_COMPANY_ROLE = "userCompanyRole",
  SILENT_INVITATION = "silentInvitation",
  USER_COMPANY = "userCompany",
  USER_COMPANY_ACCESS = "userCompanyAccess",
}
export enum eSystems {
  OMC = "1",
}
/**
 * End Enums
 */

/**
 * Start Actions types
 */
export type ContextSignupAction = PayloadAction<
  IContext,
  PermissionsActions.CONTEXT_SIGNUP
>;
export type RegisterPermissionAction = PayloadAction<
  IPermission,
  PermissionsActions.PERMISSION_REGISTER
>;
/**
 * End Actions types
 */

/**
 * Start Contexts types
 */
export interface IContext {
  name: eContexts;
  verifyType: eVerifyFunctions;
}

export type IVerifyFunctions = {
  [key in eVerifyFunctions]: (...args: any) => boolean;
};
/**
 * End Contexts types
 */

export interface IPermission {
  name: ePermissions;
  items: IPermissionItem[];
}

export interface IPermissionItem {
  context: eContexts;
  domain: string | eDomainScopes;
  actions: eActions[];
}

export const Contexts: { [key in eContexts]: IContext } = {
  [eContexts.SYSTEM]: {
    name: eContexts.SYSTEM,
    verifyType: eVerifyFunctions.SYSTEM,
  },
  [eContexts.LOCATION]: {
    name: eContexts.LOCATION,
    verifyType: eVerifyFunctions.NUMBER,
  },
  [eContexts.COMPANY]: {
    name: eContexts.COMPANY,
    verifyType: eVerifyFunctions.NUMBER,
  },
};

export interface IDomain {
  context: eContexts;
  id: string | eDomainScopes;
}

export type CanPerformFunction = (
  permissions: IPermission[],
  perform: ePermissions,
  action: eActions,
  domain: IDomain
) => boolean;
