import { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "orbitDayjs";

export const VisitorsRoutes = {
  path: "visitors",
  name: "Visitors",
};

export type IVisitorsSorterColumns = "NEW" | "CONTACT_INFO" | "LASTNAME";

export const MAPPED_COLUMNS_SORT_KEY: {
  [key: string]: IVisitorsSorterColumns;
} = {
  new: "NEW",
  contactInfo: "CONTACT_INFO",
  name: "LASTNAME",
};

export enum Actions {
  VISITORS_UPTATE_REFRESH_TIME = "VISITORS_UPTATE_REFRESH_TIME",
}

export type IRefreshTime = Dayjs | null;

export interface IVisitorsStore {
  refreshTime: IRefreshTime;
}

export type UpdateRefreshTimeAction = PayloadAction<
  null,
  Actions.VISITORS_UPTATE_REFRESH_TIME
>;
