import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { useEffect, useState } from "react";

export const useConfigureErrorReporting = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (process.env.REACT_APP_BUGSNAG_ENABLED === "true") {
      if (!process.env.REACT_APP_BUGSNAG_API_KEY)
        console.error("Bugsnag API key not defined");
      Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
        plugins: [new BugsnagPluginReact()],
        appVersion: process.env.REACT_APP_VERSION,
        releaseStage: process.env.REACT_APP_RUN_ENVIRONMENT,
        enabledReleaseStages: ["production", "staging"],
      });
      setIsLoaded(true);
    } else {
      setIsLoaded(true);
    }
  }, []);
  return isLoaded;
};
