import { SorterResult } from "antd/lib/table/interface";
import { IOrbitEmptyProps } from "components/orbit-empty/OrbitEmpty";
import authModule from "modules/auth/shared/config";
import homeModule from "modules/home/shared/config";
import notFoundModule from "modules/not-found/shared/config";
import React, { ComponentType, ForwardRefExoticComponent } from "react";
import { RouteObject } from "react-router";

// Define some styles
export type L<T> = {
  loading: boolean;
  data: T;
};

export type CompoundedComponent<Props, AdditionalProps> =
  ForwardRefExoticComponent<Props> & AdditionalProps;

export interface INavigationConfiguration {
  hideFromNavigation: boolean;
  nameTranslationKey: string;
}

export interface IModuleConfiguration {
  name: string;
  private?: boolean;
  navigation: INavigationConfiguration;
  routes: OrbitRoute[];
}

export type OrbitRoute = Omit<RouteObject, "children"> & {
  name?: string;
  children?: OrbitRoute[];
  absolutePath?: string;
};

export type Unpacked<T> = T extends (infer U)[] | ReadonlyArray<infer U> ? U : T;

export type IErrorBoundaryFallbackComponent = React.ComponentType<{
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}>;

export interface ComponentWithData<T, dataFetchComponentAdditionalProps = {}> {
  dataFetchComponent: ComponentType<
    {
      queryRef: T;
      onDataFetch?: (data: any) => void;
    } & dataFetchComponentAdditionalProps
  >;
  dataFetchComponentProps?: any;
  loadingFallback: React.JSX.Element;
  errorFallback: IErrorBoundaryFallbackComponent;
  queryRef: T;
}

interface WithEmpty {
  emptyConfig?: IOrbitEmptyProps;
}

export type ComponentWithEmpty<T> = WithEmpty & T;

export type Seter<T> = (value: T) => void;

export type AntdSorter<T = any> = SorterResult<T> | SorterResult<T>[];

export const AppRoutes = [
  ...authModule.routes,
  ...homeModule.routes,
  ...notFoundModule.routes,
];
