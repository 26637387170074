import React, { Suspense } from "react";
import { IModuleConfiguration, OrbitRoute } from "types";

import { AuthRoutes } from "./auth.types";

const LoginLazy = React.lazy(() => import("../containers/LoginContainer"));

const authRoutes: OrbitRoute[] = [
  {
    path: AuthRoutes.login.path,
    name: AuthRoutes.login.name,
    element: (
      <Suspense>
        <LoginLazy />
      </Suspense>
    ),
  },
];

export const ModuleConfiguration: IModuleConfiguration = {
  name: "Auth",
  navigation: {
    nameTranslationKey: "Auth",
    hideFromNavigation: true,
  },
  routes: authRoutes,
};

export default ModuleConfiguration;
