import { createAction } from "@reduxjs/toolkit";

import { IMenu, MenuActions } from "./menu.types";

export const menuItemRegisterAction = createAction<IMenu>(
  MenuActions.MENU_ITEM_REGISTER
);

export const collapseMenuAction = createAction(MenuActions.MENU_COLLAPSE);
export const uncollapseMenuAction = createAction(MenuActions.MENU_UNCOLLAPSE);
export const isMobileAction = createAction(MenuActions.MENU_IS_MOBILE);
export const isNotMobileAction = createAction(MenuActions.MENU_IS_NOT_MOBILE);
