import { Select, Space } from "antd";
import { BaseOptionType, SelectProps } from "antd/lib/select";
import { DropdownArrow } from "components/dropdown-arrow/DropdownArrow";
import { OrbitDivider } from "components/orbit-divider/OrbitDivider";
import { IOrbitEmptyProps, OrbitEmpty } from "components/orbit-empty/OrbitEmpty";
import { OrbitSlot } from "components/orbit-slot/OrbitSlot";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { Colors, StyleTokens } from "style.tokens";
import { styled } from "styled-components";

export interface IOrbitSelectItemProps extends BaseOptionType {
  icon?: React.ReactNode;
  options?: IOrbitSelectItemProps[];
}

export interface IOrbitSelectProps<
  ValueType,
  OptionType extends IOrbitSelectItemProps,
> extends SelectProps<ValueType, OptionType> {
  emptyState?: IOrbitEmptyProps;
  dropdownHeader?: ReactElement;
  secondary?: boolean;
}

export const OrbitSelect = <ValueType, OptionType extends IOrbitSelectItemProps>(
  props: IOrbitSelectProps<ValueType, OptionType>
) => {
  const {
    size,
    emptyState,
    children,
    options,
    dropdownHeader,
    secondary,
    className,
    ...rest
  } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [, setSelectedKey] = useState<ValueType>();

  const onChangeHandler = useCallback<
    Exclude<SelectProps<ValueType, OptionType>["onChange"], undefined>
  >((value: ValueType) => {
    setSelectedKey(value);
  }, []);
  const visibleChangeHandler = useCallback(
    (visible: boolean) => {
      props.onDropdownVisibleChange && props.onDropdownVisibleChange(visible);
      setIsDropdownVisible(visible);
    },
    [props]
  );

  const content = useMemo(() => {
    if (children) {
      return children;
    }
    if (options && options.length) {
      return options.map((item, index) => (
        <StyledSelect.Option
          key={`option-${index}`}
          value={item.value || item.label}
          label={item.label}
        >
          <Space>
            <OrbitSlot leftComponent={item.icon} label={item.label} />
          </Space>
        </StyledSelect.Option>
      ));
    }
    return (
      <OrbitEmpty
        image={emptyState?.image || <></>}
        label={emptyState?.label || ""}
        sublabel={emptyState?.sublabel || ""}
      />
    );
  }, [children, options, emptyState]);

  return (
    <StyledSelect<ValueType, OptionType>
      suffixIcon={<DropdownArrow reverse={isDropdownVisible} />}
      onDropdownVisibleChange={visibleChangeHandler}
      menuItemSelectedIcon={
        <DotContainer>
          <Dot />
        </DotContainer>
      }
      size={size || "middle"}
      onChange={onChangeHandler}
      dropdownRender={(menu) => {
        return (
          <Space direction="vertical" size={0}>
            {!!dropdownHeader && [
              dropdownHeader,
              <OrbitDivider key="divider" margins={[8, 0]} />,
            ]}
            {menu}
          </Space>
        );
      }}
      className={`${className} ${secondary ? "secondary" : ""}`}
      {...rest}
    >
      {content}
    </StyledSelect>
  );
};

export const OrbitOption = Select.Option;

export const OrbitOptionGroup = Select.OptGroup;

const StyledSelect = styled(Select)`
  &.secondary {
    .ant-select-selector {
      background-color: ${Colors.gray100};
      border: 2px solid ${Colors.gray100};
      box-shadow: none;
    }
    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        &.ant-select {
          &:not(.ant-select-customize-input)  {
            .ant-select-selector {
              border-color: ${Colors.green600};
              background-color: ${Colors.white};
              box-shadow: none;
              &:hover {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    &:not(.ant-select-disabled) {
      &:not(.ant-select-customize-input)  {
        &:not(.ant-pagination-size-changer) {
          &:hover {
            .ant-select-selector {
              background-color: ${Colors.white};
              border: 2px solid ${Colors.green600};
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .ant-select-selection-item {
    display: flex;
  }
  &.ant-select-focused {
    &:not(.ant-select-disabled) {
      &.ant-select {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            box-shadow: ${StyleTokens.elementShadowBox};
            &:hover {
              box-shadow: ${StyleTokens.elementShadowBoxHover};
            }
          }
        }
      }
    }
  }
  .ant-select-selector {
    box-shadow: ${StyleTokens.elementShadowBox};
    transition: box-shadow 0.1s;

    &:hover {
      box-shadow: ${StyleTokens.elementShadowBoxHover};
    }
  }
` as typeof Select;

const DotContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
`;
