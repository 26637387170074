import { ContextSignupAction, IPermissionsStore } from "../shared/permissions.types";

export const signupContextReducer = (
  state: IPermissionsStore,
  action: ContextSignupAction
) => {
  if (!state.contexts.find((context) => context.name === action.payload.name)) {
    state.contexts.push(action.payload);
  } else {
    console.warn("Context already exists in the store", action.payload);
  }
  return state;
};
