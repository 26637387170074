import { OverviewRoutes } from "../modules/overview/shared/overview.types";

export const MainRoutes = {
  path: "/",
  name: "Home",
  overview: {
    ...OverviewRoutes,
  },
};

export type Range = "daily" | "weekly";
