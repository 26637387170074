import { ErrorPage, IErrorPageProps } from "components/error-page/ErrorPage";
import { useTranslation } from "react-i18next";

interface IErrorScreenProps
  extends Pick<IErrorPageProps, "enableLanguageSelector"> {}

const ErrorScreen = (props: IErrorScreenProps) => {
  const { t } = useTranslation(["generic"]);

  return (
    <ErrorPage
      title={t("generic:errorPage.title")}
      subtitle={t("generic:errorPage.subtitle")}
      buttonText={t("generic:errorPage.actionButton")}
      buttonAction={() => window.location.reload()}
      enableLanguageSelector={props.enableLanguageSelector}
    />
  );
};

export default ErrorScreen;
