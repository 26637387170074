import type {
  ChangeCurrentContextAction,
  IFilterSystemStore,
} from "../shared/filterSystem.types";

export const ChangeCurrentContextReducer = (
  state: IFilterSystemStore,
  action: ChangeCurrentContextAction
) => {
  state.currentContext = action.payload;
  return state;
};
