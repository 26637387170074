import dayjs from "orbitDayjs";

export enum eDateRangeGroups {
  DAY = "day",
  WEEK = "week",
}

export enum eDateRange {
  Today = "Today",
  Yesterday = "Yesterday",
  Tomorrow = "Tomorrow",
  LastWeek = "Last Week",
  ThisWeek = "This Week",
  NextWeek = "Next Week",
}

export interface IDateRangeObject {
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
  rangeName: string;
  translationKeyRangeName: string;
  group: eDateRangeGroups;
}

export const DateRanges: { [key in eDateRange]: IDateRangeObject } = {
  [eDateRange.Today]: {
    dateRange: [dayjs().startOf("day"), dayjs().endOf("day")],
    rangeName: "Today",
    translationKeyRangeName: "generic:filters.dateRange.daysGroup.today",
    group: eDateRangeGroups.DAY,
  },
  [eDateRange.Yesterday]: {
    dateRange: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
    rangeName: "Yesterday",
    translationKeyRangeName: "generic:filters.dateRange.daysGroup.yesterday",
    group: eDateRangeGroups.DAY,
  },
  [eDateRange.Tomorrow]: {
    dateRange: [
      dayjs().add(1, "day").startOf("day"),
      dayjs().add(1, "day").endOf("day"),
    ],
    rangeName: "Tomorrow",
    translationKeyRangeName: "generic:filters.dateRange.daysGroup.tomorrow",
    group: eDateRangeGroups.DAY,
  },
  [eDateRange.LastWeek]: {
    dateRange: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
    rangeName: "Last Week",
    translationKeyRangeName: "generic:filters.dateRange.weeksGroup.lastWeek",
    group: eDateRangeGroups.WEEK,
  },
  [eDateRange.ThisWeek]: {
    dateRange: [dayjs().startOf("week"), dayjs().endOf("week")],
    rangeName: "This Week",
    translationKeyRangeName: "generic:filters.dateRange.weeksGroup.thisWeek",
    group: eDateRangeGroups.WEEK,
  },
  [eDateRange.NextWeek]: {
    dateRange: [
      dayjs().add(1, "week").startOf("week"),
      dayjs().add(1, "week").endOf("week"),
    ],
    rangeName: "Next Week",
    translationKeyRangeName: "generic:filters.dateRange.weeksGroup.nextWeek",
    group: eDateRangeGroups.WEEK,
  },
};
