import { eBreakpoints } from "config";
import { Max } from "styled-breakpoints/styled-breakpoints/create-styled-breakpoints-theme/create-styled-breakpoints-theme";

export const Colors = {
  hueTransparent: "rgba(27, 43, 89, 0.06)",
  solidTransparent: "rgba(136, 141, 155, 0.3)",

  overlayDark: "rgba(22, 23, 26, 0.16)",
  overlayDarker: "rgba(22, 23, 26, 0.5)",

  green50: "#E7FFF9",
  green100: "#BEFFEF",
  green200: "#94FFE5",
  green300: "#6AFFDB",
  green400: "#40FFD1",
  green500: "#16FDC5",
  green600: "#00D4A1",
  green700: "#00AB82",
  green800: "#008263",
  green900: "#005A44",

  purple50: "#F3EFFF",
  purple100: "#D9CDFF",
  purple200: "#C0AAFF",
  purple300: "#A688FF",
  purple400: "#8C65FF",
  purple500: "#734FDD",
  purple600: "#5B3BBB",
  purple700: "#462A99",
  purple800: "#331C77",
  purple900: "#221155",

  orange50: "#FFF6EB",
  orange100: "#FFE2C0",
  orange200: "#FFCD94",
  orange300: "#FFB969",
  orange400: "#FFA53D",
  orange500: "#DD8A2A",
  orange600: "#BB701A",
  orange700: "#99580D",
  orange800: "#774204",
  orange900: "#552E00",

  red50: "#FFEEEC",
  red100: "#FFC9C1",
  red200: "#FFA497",
  red300: "#FF7F6C",
  red400: "#FF5A42",
  red500: "#DD442E",
  red600: "#BB321E",
  red700: "#992211",
  red800: "#771507",
  red900: "#550C01",

  white: "white",
  gray50: "#FDFDFD",
  gray100: "#F1F2F3",
  gray200: "#E3E4E8",
  gray300: "#C8CAD0",
  gray400: "#ACAFB9",
  gray500: "#9195A1",
  gray600: "#5E626E",
  gray700: "#464953",
  gray800: "#2F3137",
  black: "#16171A",

  gradient1: "linear-gradient(180deg, #48DAB7 0%, #8C65FF 100%);",
  gradient2: "linear-gradient(240.64deg, #48DAB7 0%, #8C65FF 100%)",
  gradient3: "linear-gradient(119.36deg, #02DCA8 0%, #00DE83 100%);",
  gradient4: "linear-gradient(240.64deg, #906CFB 0%, #524AE6 100%)",
  gradient5: "linear-gradient(63.16deg, #16171A 1.87%, #2B2C33 89.13%)",
  gradient6: "linear-gradient(240.64deg, #16171A 0%, #2B2C33 100%)",
};

export const StyleTokens = {
  siderWidth: 280,
  primaryColor: Colors.black,
  backgroundColor: Colors.gray100,
  borderRadius: 10,
  mainTextColor: Colors.black,
  fontSizeBase: 15,
  dropdownBaseWidth: 200,
  cardPadding: "12px",
  tooltipContentPadding: "12px 13px",
  baseBorderRadius: "10px",
  sidebarWidth: "280px",
  loginScreenCollapseBreakpoint: eBreakpoints.medium,
  layoutBodyBackground: Colors.gray100,
  inputPrefixIconColor: "#848999",
  smallShadow: "0px 0px 5px rgba(65, 76, 109, 0.15)",
  mediumShadow: "0px 4px 10px rgba(65, 76, 109, 0.15)",
  elementShadowBox:
    "0px 1px 3px 0px rgb(65 76 109 / 12%),0px 4px 8px 0px rgb(101 106 123 / 8%)",
  elementShadowBoxHover:
    "0px 1px 3px 0px rgb(65 76 109 / 12%),0px 4px 8px 0px rgb(101 106 123 / 20%)",

  h1FontSize: 94,
  h1LineHeight: 104,
  h1FontWeight: 600,

  h2FontSize: 59,
  h2LineHeight: 66,
  h2FontWeight: 600,

  h3FontSize: 47,
  h3LineHeight: 54,
  h3FontWeight: 500,

  h4FontSize: 33,
  h4LineHeight: 40,
  h4FontWeight: 600,

  h5FontSize: 25,
  h5LineHeight: 32,
  h5FontWeight: 600,

  h6FontSize: 20,
  h6LineHeight: 24,
  h6FontWeight: 700,

  subtitle1FontSize: 16,
  subtitle1LineHeight: 24,
  subtitle1FontWeight: 600,

  subtitle2FontSize: 14,
  subtitle2LineHeight: 24,
  subtitle2FontWeight: 600,

  body1FontSize: 16,
  body1LineHeight: 26,
  body1FontWeight: 400,

  body2FontSize: 14,
  body2LineHeight: 24,
  body2FontWeight: 400,

  buttonFontSize: 14,
  buttonLineHeight: 24,
  buttonFontWeight: 500,

  captionFontSize: 12,
  captionLineHeight: 18,
  captionFontWeight: 400,

  overlineFontSize: 10,
  overlineLineHeight: 16,
  overlineFontWeight: 500,
};

const mobileMaxBreakpoint = eBreakpoints.large;

interface IBreakpointTokens {
  mobileMaxBreakpoint: eBreakpoints;
  sidebarCollapseBreakpoint: Max;
  loginScreenCollapseBreakpoint: Max;
}

export const BreakpointTokens: IBreakpointTokens = {
  mobileMaxBreakpoint: mobileMaxBreakpoint,
  sidebarCollapseBreakpoint: mobileMaxBreakpoint,
  loginScreenCollapseBreakpoint: eBreakpoints.medium,
};
