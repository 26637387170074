import { LanguageSelector } from "components/language-selector/LanguageSelector";
import { OrbitButton } from "components/orbit-button";
import { ConfusedOrbitaIllustration } from "components/orbit-icon/icons-components/ConfusedOrbitaIllustration";
import OrbitText from "components/orbit-text/OrbitText";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useMemo } from "react";
import { Colors } from "style.tokens";
import styled from "styled-components";

export interface IErrorPageProps {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonAction: () => void;
  enableLanguageSelector?: boolean;
}

export const ErrorPage = (props: IErrorPageProps) => {
  const { height, width } = useWindowDimensions();
  const isVertical = useMemo(() => {
    return height > width;
  }, [height, width]);

  return (
    <Container>
      <FeedbackContainer isVertical={isVertical}>
        <ConfusedOrbitaIllustration size={isVertical ? 180 : 300} />
        <TextContainer isVertical={isVertical}>
          <StyledH4 isVertical={isVertical}>
            <>{props.title}</>
          </StyledH4>
          <StyledSubtitle weak isVertical={isVertical}>
            {props.subtitle.split("\n").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </StyledSubtitle>
          <StyledOrbitButton type="primary" onClick={props.buttonAction}>
            <>{props.buttonText}</>
          </StyledOrbitButton>
        </TextContainer>
      </FeedbackContainer>
      {props.enableLanguageSelector && <LanguageSelector />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.gray100};
  padding-bottom: 5vh;
`;

const FeedbackContainer = styled.div<{ isVertical: boolean }>`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.isVertical ? "column" : "row")};
  gap: 5vw;
`;

const TextContainer = styled.div<{ isVertical: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: ${(props) => (props.isVertical ? "center" : "flex-start")};
`;

const StyledH4 = styled(OrbitText.H4)<{ isVertical: boolean }>`
  text-align: ${(props) => (props.isVertical ? "center" : "inherit")};
`;

const StyledSubtitle = styled(OrbitText.Subtitle1)<{ isVertical: boolean }>`
  font-weight: 500;
  text-align: ${(props) => (props.isVertical ? "center" : "inherit")};
`;

const StyledOrbitButton = styled(OrbitButton)`
  margin-top: 16px;
`;
