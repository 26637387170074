import { App as AntdApp, ConfigProvider, theme } from "antd";
import { AntdComponentsTokens, AntdTokens } from "antd.tokens";
import { OrbitBreakpointsDebugger } from "components/orbit-breakpoints-debugger/OrbitBreakpointsDebugger";
import { OrbitEmpty } from "components/orbit-empty/OrbitEmpty";
import { OrbitPageLoader } from "components/orbit-page-loader/OrbitPageLoader";
import { OrbitRootProvider } from "components/orbit-root-provider/OrbitRootProvider";
import { useDayjsConfiguration } from "hooks/useDayjsConfiguration";
import i18next from "i18next";
import { AnalyticsProvider } from "modules/analytics/shared/components/AnalyticsProvider";
import { useConfigureAnalytics } from "modules/analytics/shared/hooks/useConfigureAnalytics";
import { ErrorBoundary } from "modules/error-reporting/shared/components/ErrorBoundary";
import ErrorScreen from "modules/error-reporting/shared/components/ErrorScreen";
import { useConfigureErrorReporting } from "modules/error-reporting/shared/hooks/useConfigureErrorReporting";
import PermissionsSetup from "modules/permissions/shared/config";
import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { RelayEnvironmentProvider } from "react-relay";
import { Outlet, useLocation } from "react-router";
import { PersistGate } from "redux-persist/integration/react";
import styled from "styled-components";
import { getRouteObject } from "utils/routerUtils";

import environment from "./RelayEnvironment";
import store, { persistor } from "./store";

export const App = () => {
  useDayjsConfiguration();
  const isLoaded = useConfigureErrorReporting();
  const analytics = useConfigureAnalytics();
  let location = useLocation();

  useEffect(() => {
    PermissionsSetup();
  }, []);

  useEffect(() => {
    analytics?.page(
      "omc",
      getRouteObject(location.pathname)?.name || location.pathname,
      {
        search: location.search,
        title: getRouteObject(location.pathname)?.name || document.title,
        state: location.state,
      }
    );
  }, [analytics, location]);

  if (!isLoaded) return null;
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <OrbitRootProvider>
            <OrbitBreakpointsDebugger />
            <AnalyticsProvider instance={analytics}>
              <RelayEnvironmentProvider environment={environment}>
                <Suspense fallback={<OrbitPageLoader />}>
                  <ConfigProvider
                    theme={{
                      algorithm: theme.defaultAlgorithm,
                      token: AntdTokens,
                      components: AntdComponentsTokens,
                    }}
                    renderEmpty={() => (
                      <OrbitEmpty sublabel={i18next.t("generic:noData")} />
                    )}
                  >
                    <AntdApp>
                      <ErrorBoundary
                        FallbackComponent={() => (
                          <ErrorContainer>
                            <ErrorScreen enableLanguageSelector />
                          </ErrorContainer>
                        )}
                      >
                        <Outlet />
                      </ErrorBoundary>
                    </AntdApp>
                  </ConfigProvider>
                </Suspense>
              </RelayEnvironmentProvider>
            </AnalyticsProvider>
          </OrbitRootProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

const ErrorContainer = styled.div`
  height: 100vh;
`;
