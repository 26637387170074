import { useBreakpointsDebugger } from "hooks/useBreakpointsDebugger";
import { isDevelopment } from "utils/isDevelopment";

const CallerComponent = () => {
  useBreakpointsDebugger();
  return null;
};

export const OrbitBreakpointsDebugger = () => {
  return isDevelopment ? <CallerComponent /> : null;
};
