import { PayloadAction } from "@reduxjs/toolkit";
import { eFeatureFlags } from "modules/feature-flags/shared/featureFlags.types";
import React from "react";

export interface IMenuStore {
  items: IMenu[];
  isCollapsed: boolean;
  isMobile: boolean;
}

export interface IMenu {
  key: string;
  path: string;
  icon: React.JSX.Element;
  nameTranslationKey: string;
  featureFlag?: eFeatureFlags;
}

export enum MenuActions {
  MENU_ITEM_REGISTER = "menu/item/register",
  MENU_COLLAPSE = "menu/collapse/on",
  MENU_UNCOLLAPSE = "menu/collapse/off",
  MENU_IS_MOBILE = "menu/mobile/on",
  MENU_IS_NOT_MOBILE = "menu/mobile/off",
}

export type ItemRegisterAction = PayloadAction<
  IMenu,
  MenuActions.MENU_ITEM_REGISTER
>;

export type CollapseMenuAction = PayloadAction<MenuActions.MENU_COLLAPSE>;
export type UncollapseMenuAction = PayloadAction<MenuActions.MENU_UNCOLLAPSE>;

export type IsMobileAction = PayloadAction<MenuActions.MENU_IS_MOBILE>;
export type IsNotMobileAction = PayloadAction<MenuActions.MENU_IS_NOT_MOBILE>;
