import { createSlice } from "@reduxjs/toolkit";

import { UpdateRefreshTimeReducer } from "../reducers/updateRefreshTime.reducer";
import { bookingsUpdateRefreshTime } from "./bookings.actions";
import { IBookingsStore } from "./bookings.types";

const initialState: () => IBookingsStore = () => {
  return {
    refreshTime: null,
  };
};

const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {},
  extraReducers: {
    [bookingsUpdateRefreshTime.type]: UpdateRefreshTimeReducer,
  },
});

export const bookingsReducer = bookingsSlice.reducer;
