import { Space } from "antd";
import { OrbitBody } from "components/orbit-body/OrbitBody";
import { LoadingIcon } from "components/orbit-icon/icons-components/LoadingIcon";
import { SupervisorIcon } from "components/orbit-icon/icons-components/SupervisorIcon";
import { ErrorBoundary } from "modules/error-reporting/shared/components/ErrorBoundary";
import ErrorScreen from "modules/error-reporting/shared/components/ErrorScreen";
import { RequireFeatureFlag } from "modules/feature-flags/shared/components/RequireFeatureFlag";
import { eFeatureFlags } from "modules/feature-flags/shared/featureFlags.types";
// import { MainRoutes } from "modules/home/shared/home.types";
import { menuItemRegisterAction } from "modules/menu/shared/menu.actions";
import React, { Suspense } from "react";
import { Navigate } from "react-router";
import store from "store";
import type { IModuleConfiguration, OrbitRoute } from "types";

import { UsersManagementRoutes as UsersManagementRouteConfig } from "./usersManagement.types";

const FEATURE_FLAG = eFeatureFlags.usersManagement;

const UsersManagementContainerLazy = React.lazy(
  () => import("../containers/UsersManagementContainer")
);

const UsersManagementRoutes: OrbitRoute[] = [
  {
    path: UsersManagementRouteConfig.path,
    name: UsersManagementRouteConfig.name,
    element: (
      <RequireFeatureFlag
        featureFlag={FEATURE_FLAG}
        fallback={<Navigate to={"/"} replace={true} />}
      >
        <ErrorBoundary FallbackComponent={() => <ErrorScreen />}>
          <Suspense
            fallback={
              <OrbitBody>
                <Space>
                  <LoadingIcon />
                  Loading UsersManagement page
                </Space>
              </OrbitBody>
            }
          >
            <UsersManagementContainerLazy />
          </Suspense>
        </ErrorBoundary>
      </RequireFeatureFlag>
    ),
  },
];

store.dispatch(
  menuItemRegisterAction({
    key: "UsersManagement",
    nameTranslationKey: "onboarding:routes.users-management",
    path: UsersManagementRouteConfig.path,
    icon: <SupervisorIcon size={24} />,
    featureFlag: FEATURE_FLAG,
  })
);

// Module configuration based on IModuleConfiguration interface
export const ModuleConfiguration: IModuleConfiguration = {
  name: "UsersManagement",
  navigation: {
    nameTranslationKey: "onboarding:routes.UsersManagement",
    hideFromNavigation: false,
  },
  private: true,
  routes: UsersManagementRoutes,
};

export default ModuleConfiguration;
