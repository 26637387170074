import { AppRoutes, OrbitRoute } from "types";

type IMapperItem = OrbitRoute;

interface IMapper {
  [key: string]: IMapperItem;
}

export const getRouteURLMapper = (
  parentPath: string,
  route: OrbitRoute
): IMapper => {
  if (!route.children || route.children.length === 0) {
    if (!route.path) return {};
    return {
      [(parentPath + "/" + route.path).replace(/[/]{2,}/g, "/")]: {
        ...route,
        absolutePath: (parentPath + "/" + route.path).replace(/[/]{2,}/g, "/"),
      },
    };
  }
  return route.children.reduce((acc, item) => {
    return {
      ...acc,
      ...getRouteURLMapper(`${parentPath}/${route.path}` || "/", item),
    };
  }, {} as IMapper);
};

export const getURLMapper = (): IMapper => {
  return AppRoutes.reduce((prev, curr) => {
    return Object.assign(prev, getRouteURLMapper("/", curr));
  }, {} as IMapper);
};

export const getRouteObject = (path: string) => {
  return getURLMapper()[path];
};

export const getRouteObjectByName = (name: string) => {
  return Object.values(getURLMapper()).find((r) => r.name === name);
};

export const getRouteAbsolutePath = (routeName: string) => {
  return getRouteObjectByName(routeName)?.absolutePath;
};
