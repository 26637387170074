// your-app-name/src/RelayEnvironment.js
import { Environment, RecordSource, Store } from "relay-runtime";

import customNetwork from "./fetchGraphQL";

// Relay passes a "params" object with the query name`p and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.

// Export a singleton instance of Relay Environment configured with our network function:
const environment = new Environment({
  network: customNetwork,
  store: new Store(new RecordSource()),
});
export default environment;
