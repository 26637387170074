import styled from "styled-components";

import { IOrbitIconProps } from "../OrbitIcon";

const LoaderPng = require("../icons-png/Loader.png");

export const LoadingIcon = (props: Omit<IOrbitIconProps, "icon" | "ref">) => {
  return <StyledOrbitIcon src={LoaderPng} {...props} />;
};

const StyledOrbitIcon = styled.img`
  animation: spin 1.5s linear infinite;
`;
