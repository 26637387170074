import type { Analytics } from "@segment/analytics-next";

export interface IAnalyticsContext {
  instance: Analytics | undefined;
}

export interface ILocationAnalytics {
  id: number;
  name: string;
}

export const AnalyticsMock = {
  track: () => {},
  identify: () => {},
  instance: undefined,
};
