import graphql from "babel-plugin-relay/macro";

export default graphql`
  query OverviewQuery(
    $from: DateTimeTz!
    $to: DateTimeTz!
    $locations: [Int!]
    $insightScope: String!
    $today: DateTimeTz!
  ) {
    visitorsInsights(
      from: $from
      to: $to
      locations: $locations
      insightScope: $insightScope
    ) {
      ...VisitorsTodayInfoBox_VisitorsInsights
      ...RevisitorsTodayInfoBox_VisitorsInsights
    }
    utilizationInsights(
      date: $from
      locations: $locations
      insightScope: $insightScope
    ) {
      ...AverageUtilizationInfoBox_UtilizationInsights
      ...UtilizationLastWeekInfoBox_UtilizationInsights
      ...OccupancyThisWeek_UtilizationInsights
    }
    visitors(
      from: $from
      to: $to
      locations: $locations
      first: 5
      page: 1
      orderBy: [
        { column: LAST_BOOKING, order: DESC }
        { column: LASTNAME, order: DESC }
      ]
    ) {
      ...VisitorsTodayList_VisitorPaginator
    }
    recentBookings(date: $today, locations: $locations, first: 6) {
      ...RecentBookings_RecentBookingConnection
    }
  }
`;
