import { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "orbitDayjs";
import { Colors } from "style.tokens";

export enum eOccasionType {
  all = "all",
  desk = "desk",
  room = "room",
}

export const BookingsRoutes = {
  path: "bookings",
  name: "Bookings",
};

export type IBookingsSorterColumns =
  | "LOCATION_NAME"
  | "SPACE_NAME"
  | "DATE"
  | "TIME"
  | "VISITOR_FIRST_NAME"
  | "VISITOR_LAST_NAME";

export const MAPPED_COLUMNS_SORT_KEY: {
  [key: string]: IBookingsSorterColumns;
} = {
  space: "SPACE_NAME",
  location: "LOCATION_NAME",
  date: "DATE",
  time: "TIME",
  visitor: "VISITOR_LAST_NAME",
};

export enum eBookingStatus {
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  FINISHED = "FINISHED",
  CHECKED_OUT = "CHECKED_OUT",
  CANCELED = "CANCELED",
  NEW = "NEW",
  PAID = "PAID",
}

export type DatabaseBookingStatus =
  | "upcoming"
  | "ongoing"
  | "finished"
  | "checkedOut"
  | "canceled"
  | "new"
  | "paid";

export const BOOKING_STATUS_DATABASE_MAPPER: {
  [key in DatabaseBookingStatus]: eBookingStatus;
} = {
  upcoming: eBookingStatus.UPCOMING,
  ongoing: eBookingStatus.ONGOING,
  finished: eBookingStatus.FINISHED,
  checkedOut: eBookingStatus.CHECKED_OUT,
  canceled: eBookingStatus.CANCELED,
  new: eBookingStatus.NEW,
  paid: eBookingStatus.PAID,
};

interface IBookingStatusConfig {
  key:
    | "Upcoming"
    | "Ongoing"
    | "Finished"
    | "Checked out"
    | "Canceled"
    | "New"
    | "Paid";
  translationKey: string;
  bgColor: string;
  textColor: string;
  secondaryBgColor: string;
  secondaryTextColor: string;
  secondaryBorderColor?: string;
}

export const BookingStatus: { [key in eBookingStatus]: IBookingStatusConfig } = {
  [eBookingStatus.UPCOMING]: {
    key: "Upcoming",
    translationKey: "bookings:statuses.upcoming",
    bgColor: Colors.gradient4,
    textColor: Colors.white,
    secondaryBgColor: Colors.purple50,
    secondaryTextColor: Colors.purple600,
    secondaryBorderColor: Colors.purple200,
  },
  [eBookingStatus.ONGOING]: {
    key: "Ongoing",
    translationKey: "bookings:statuses.ongoing",
    bgColor: Colors.green600,
    textColor: Colors.black,
    secondaryBgColor: Colors.green100,
    secondaryTextColor: Colors.green800,
    secondaryBorderColor: Colors.green200,
  },
  [eBookingStatus.FINISHED]: {
    key: "Finished",
    translationKey: "bookings:statuses.finished",
    bgColor: Colors.black,
    textColor: Colors.white,
    secondaryBgColor: Colors.gray600,
    secondaryTextColor: Colors.white,
    secondaryBorderColor: Colors.black,
  },
  [eBookingStatus.CHECKED_OUT]: {
    key: "Checked out",
    translationKey: "bookings:statuses.checkedOut",
    bgColor: Colors.gray500,
    textColor: Colors.white,
    secondaryBgColor: Colors.gray200,
    secondaryTextColor: Colors.black,
    secondaryBorderColor: Colors.gray400,
  },
  [eBookingStatus.CANCELED]: {
    key: "Canceled",
    translationKey: "bookings:statuses.canceled",
    bgColor: Colors.red400,
    textColor: Colors.white,
    secondaryBgColor: Colors.red100,
    secondaryTextColor: Colors.red500,
    secondaryBorderColor: Colors.red200,
  },
  [eBookingStatus.NEW]: {
    key: "New",
    translationKey: "bookings:statuses.new",
    bgColor: Colors.gradient2,
    textColor: Colors.white,
    secondaryBgColor: Colors.gradient2,
    secondaryTextColor: Colors.white,
  },
  [eBookingStatus.PAID]: {
    key: "Paid",
    translationKey: "bookings:statuses.paid",
    bgColor: Colors.green600,
    textColor: Colors.black,
    secondaryBgColor: Colors.green100,
    secondaryTextColor: Colors.green800,
    secondaryBorderColor: Colors.green200,
  },
};

export enum Actions {
  BOOKINGS_UPTATE_REFRESH_TIME = "BOOKINGS_UPTATE_REFRESH_TIME",
}

export type IRefreshTime = Dayjs | null;

export interface IBookingsStore {
  refreshTime: IRefreshTime;
}

export type UpdateRefreshTimeAction = PayloadAction<
  null,
  Actions.BOOKINGS_UPTATE_REFRESH_TIME
>;
