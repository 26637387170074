import "./index.css";

import { MainRoutes } from "modules/home/shared/home.types";
// import { loadAnalytics } from "modules/analytics/shared/utils/loadAnalytics";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { AppRoutes } from "types";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

// import { Metric } from "web-vitals";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");

if (container === null) throw Error("Not able to find #root in public/index.html");

const root = ReactDOMClient.createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <App />
      </QueryParamProvider>
    ),
    children: [
      { path: "/", element: <Navigate to={MainRoutes.path} /> },
      ...AppRoutes.map((route) => route as RouteObject),
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// const sendToAnalytics = async (metric: Metric) => {
//   (await loadAnalytics())?.track("Web vitals", {
//     ...metric,
//   });
// };
reportWebVitals(/* sendToAnalytics */);
