import { createSlice } from "@reduxjs/toolkit";

import { UpdateRefreshTimeReducer } from "../reducers/updateRefreshTime.reducer";
import { overviewUpdateRefreshTime } from "./overview.actions";
import { IOverviewStore } from "./overview.types";

const initialState: () => IOverviewStore = () => {
  return {
    refreshTime: null,
  };
};

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {},
  extraReducers: {
    [overviewUpdateRefreshTime.type]: UpdateRefreshTimeReducer,
  },
});

export const overviewReducer = overviewSlice.reducer;
