export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

export const EnumToValuesArray = <T>(E: { [key: string]: T }): T[] => {
  return Object.keys(E)
    .filter((k: string) => typeof E[k] !== "number")
    .map((k: string) => E[k]);
};

export function splitEnumKeysAndValues<T extends object>(
  value: T
): {
  keys: (keyof T)[];
  values: T[keyof T][];
} {
  const keys = enumKeys(value);
  return {
    keys,
    values: keys.map((key) => value[key]),
  };
}

export function getEnumValues<T extends object>(value: T): T[keyof T][] {
  const { values } = splitEnumKeysAndValues<T>(value);
  return values;
}

export function getEnumValueByKey<E extends object, K extends keyof E = keyof E>(
  obj: E,
  enumKey: K
): E[keyof E][] {
  return enumKeys(obj)
    .filter((key) => key === enumKey)
    .map((v) => obj[v]);
}

export function isEnumType<E>(
  enumerable: { [key: string]: E },
  value: any
): value is E {
  return EnumToValuesArray<E>(enumerable).includes(value);
}
