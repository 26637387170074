import type {
  IFilterSystemStore,
  SetValueForAllAction,
} from "../shared/filterSystem.types";

export const SetValueForAllReducer = (
  state: IFilterSystemStore,
  action: SetValueForAllAction
) => {
  const { payload } = action;
  const { key, value } = payload;
  state.values[key] = value as any;
  return state;
};
