import { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "orbitDayjs";

export const OverviewRoutes = {
  path: "overview",
  name: "Overview",
};

export enum Actions {
  OVERVIEW_UPTATE_REFRESH_TIME = "OVERVIEW_UPTATE_REFRESH_TIME",
}

export type IRefreshTime = Dayjs | null;

export interface IOverviewStore {
  refreshTime: IRefreshTime;
}

export type UpdateRefreshTimeAction = PayloadAction<
  null,
  Actions.OVERVIEW_UPTATE_REFRESH_TIME
>;
