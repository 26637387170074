import OrbitText from "components/orbit-text/OrbitText";
import { IOrbitTextBaseProps } from "components/orbit-text/OrbitText.types";
import { ReactNode } from "react";
import styled from "styled-components";

export interface IOrbitSlotProps {
  label: string | React.ReactNode;
  leftComponent?: ReactNode;
  sublabel?: string;
  subsublabel?: string;
  className?: string;
  align?: "left" | "right";
  textComponent?: React.ComponentType<IOrbitTextBaseProps>;
  textComponentSublabel?: React.ComponentType<IOrbitTextBaseProps>;
  isCollapsed?: boolean;
  labelStyles?: React.CSSProperties;
  fitParent?: boolean;
  ellipsis?: boolean;
}

export const OrbitSlot = (props: IOrbitSlotProps) => {
  const {
    leftComponent,
    label,
    sublabel,
    subsublabel,
    align = "left",
    textComponent,
    isCollapsed,
    labelStyles,
    ellipsis,
    fitParent,
    textComponentSublabel,
    ...others
  } = props;
  const TextComponent = textComponent || OrbitText.Body2;
  const TextComponentSublabel = textComponentSublabel || TextComponent;

  return (
    <SlotContainer {...others}>
      {leftComponent}
      <LabelsContainer
        $fitParent={fitParent}
        $isCollapsed={isCollapsed}
        className={`align-${align}`}
        style={labelStyles}
      >
        <TextComponent ellipsis={ellipsis} lineHeight={18}>
          {label}
        </TextComponent>
        <TextComponentSublabel ellipsis={ellipsis} lineHeight={18} weak>
          {sublabel}
        </TextComponentSublabel>
        {subsublabel && (
          <TextComponent ellipsis={ellipsis} lineHeight={18} weak>
            {subsublabel}
          </TextComponent>
        )}
      </LabelsContainer>
    </SlotContainer>
  );
};

const SlotContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    white-space: nowrap;
  }
`;

const LabelsContainer = styled.div<{
  $isCollapsed: IOrbitSlotProps["isCollapsed"];
  $fitParent: IOrbitSlotProps["fitParent"];
}>`
  transition:
    all 0.3s ease-in-out,
    color 0.1s;
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.$isCollapsed ? 0 : 1)};
  margin-left: 4px;
  min-width: ${(props) => (props.$fitParent ? 0 : "inherit")};

  &.align-right {
    align-items: flex-end;
  }
`;
