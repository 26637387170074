import { PayloadAction } from "@reduxjs/toolkit";
import { Seter } from "types";

import { eInsightScopes } from "../components/insight-scope-selector/InsightScopeSelector.types";
import type { ILocation } from "../components/location-filter/locationFilter.types";
import { IDateRangeObject } from "../components/relative-date-selector/RelativeDateSelector.types";

/**
 * 🔽 REDUX TYPES
 */
export enum eFilters {
  LOCATION_SELECTOR = "LOCATION_SELECTOR",
  DATE_RANGE = "DATE_RANGE",
  INSIGHT_SCOPE = "INSIGHT_SCOPE",
}
export enum PredefinedContexts {
  GLOBAL = "global",
}
export enum Actions {
  FILTER_SYSTEM_SET_VALUE_FOR_ALL = "FILTER_SYSTEM_SET_VALUE_FOR_ALL",
  FILTER_SYSTEM_SET_VALUE_FOR_CONTEXT = "FILTER_SYSTEM_SET_VALUE_FOR_CONTEXT",
  FILTER_SYSTEM_CREATE_CONTEXT = "FILTER_SYSTEM_CREATE_CONTEXT",
  FILTER_SYSTEM_CHANGE_CURRENT_CONTEXT = "FILTER_SYSTEM_CHANGE_CURRENT_CONTEXT",
}
export type Context = PredefinedContexts | string;

/**
 * 🔽 Filters Values & Seters Types
 */
export type LocationsValue = ILocation[];
export type SetLocations = Seter<LocationsValue>;

export type SelectedLocationValue = ILocation | null;
export type SetSelectedLocation = Seter<SelectedLocationValue>;

export type SelectedLocationForQueriesValue = number[] | null;
export type SetSelectedLocationForQueries = Seter<SelectedLocationForQueriesValue>;

export type DateRangeValue = IDateRangeObject;
export type SetDateRange = Seter<DateRangeValue>;

export type InsightScopeValue = eInsightScopes;
export type SetInsightScope = Seter<InsightScopeValue>;
/**
 * 🔼 Filters Values & Seters Types
 */

export interface IFilterValues {
  locations: LocationsValue;
  selectedLocation: SelectedLocationValue;
  selectedLocationForQueries: SelectedLocationForQueriesValue;
  dateRange: DateRangeValue;
  insightScope: InsightScopeValue;
}
export interface IFilterSeters {
  setLocations: SetLocations;
  setSelectedLocation: SetSelectedLocation;
  setSelectedLocationForQueries: SetSelectedLocationForQueries;
  setDateRange: SetDateRange;
  setInsightScope: SetInsightScope;
}

export interface IContext {
  name: Context;
  filters: eFilters[];
}

export interface ISetValueForAllPayload<
  K extends keyof IFilterValues = keyof IFilterValues,
> {
  key: K;
  value: IFilterValues[K];
}

export type ISetValueForContextPayload = ISetValueForAllPayload & {
  context: IContext["name"];
};

export interface IFilterSystemStore {
  currentContext: Context;
  contexts: IContext[];
  values: IFilterValues;
}

/**
 * 🔽 Action Types
 */
export type CreateContextAction = PayloadAction<
  IContext,
  Actions.FILTER_SYSTEM_CREATE_CONTEXT
>;
export type SetValueForAllAction = PayloadAction<
  ISetValueForAllPayload,
  Actions.FILTER_SYSTEM_SET_VALUE_FOR_CONTEXT
>;
export type ChangeCurrentContextAction = PayloadAction<
  Context,
  Actions.FILTER_SYSTEM_CHANGE_CURRENT_CONTEXT
>;
/**
 * 🔼 Action Types
 */

/**
 * 🔽 useFilterSystem Hook Types
 */
export type IUseFilterSystemHookReturn = IFilterValues & IFilterSeters;
export type IFilterSystemConfigurationObject = Omit<IContext, "name">;
/**
 * 🔼 useFilterSystem Hook Types
 */

/**
 * 🔼 REDUX TYPES
 */
