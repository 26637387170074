import { gql } from "graphql-tag";
import i18n from "i18n";
import { cleanSession } from "modules/auth/shared/utils/cleanSession";
import { readSession } from "modules/auth/shared/utils/readSession";
import { storeSession } from "modules/auth/shared/utils/storeSession";
import { ErrorReporter } from "modules/error-reporting/shared/utils/ErrorReporter";
import {
  cacheMiddleware,
  errorMiddleware,
  loggerMiddleware,
  perfMiddleware,
  RelayNetworkLayer,
  retryMiddleware,
  urlMiddleware,
} from "react-relay-network-modern";
import { getStableStorageKey } from "relay-runtime/lib/store/RelayStoreUtils";
import { isDebug } from "utils/isDebug";
import { isDevelopment } from "utils/isDevelopment";

const keys = new Map<string, Set<string>>();

const network = new RelayNetworkLayer(
  [
    cacheMiddleware({
      size: 100, // max 100 requests
      ttl: 900000, // 15 minutes
    }),
    urlMiddleware({
      url: () =>
        Promise.resolve(
          `${process.env.REACT_APP_API_GATEWAY}${process.env.REACT_APP_GRAPHQL_URL}`
        ),
    }),
    (next) => async (req) => {
      const res = await next(req);

      if (res.errors) {
        if (res.errors.some((error) => error.message.includes("Unauthenticated"))) {
          await fetch(
            `${process.env.REACT_APP_API_GATEWAY}${process.env.REACT_APP_GRAPHQL_URL}`,
            {
              method: "POST",
              body: JSON.stringify({
                query: `mutation RefreshToken($input: RefreshTokenInput!) { tokenRefresh(input: $input) {
                    id
                    token
                    refreshToken
                    tokenExpiresAt
                  } }`,
                variables: { input: { refreshToken: readSession().refreshToken } },
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "device-language": i18n.language,
              },
            }
          )
            .then((response) => {
              return response.json();
            })
            .then(async (json) => {
              if (json.data.tokenRefresh) {
                const { token, tokenExpiresAt, refreshToken } =
                  json.data.tokenRefresh;
                storeSession(token, tokenExpiresAt, refreshToken);
              } else {
                cleanSession();
              }
            })
            .catch((err) =>
              ErrorReporter.notify({ name: "RefreshToken", message: err })
            );
          return next(req);
        }
      }
      return res;
    },
    (next) => (req) => {
      if (typeof req.fetchOpts.body === "string") {
        const bodyValues = JSON.parse(req.fetchOpts.body);
        req.fetchOpts.body = JSON.stringify({
          query: bodyValues.query,
          variables: bodyValues.variables,
          operationName: bodyValues.id,
        });
      }
      const session = readSession();
      if (session.accessToken) {
        req.fetchOpts.headers["Authorization"] = `Bearer ${session.accessToken}`;
      }

      req.fetchOpts.headers["device-language"] = i18n.language;

      return next(req);
    },
    isDevelopment ? loggerMiddleware() : null,
    isDevelopment ? errorMiddleware() : null,
    isDevelopment ? perfMiddleware() : null,
    retryMiddleware({
      fetchTimeout: 15000,
      retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
      beforeRetry: ({ forceRetry, abort, delay, attempt }) => {
        if (attempt > 10) abort();
        (window as any).forceRelayRetry = forceRetry;
        isDebug() &&
          console.log(
            "call `forceRelayRetry()` for immediately retry! Or wait " +
              delay +
              " ms."
          );
      },
      statusCodes: [500, 503, 504],
    }),
    (next) => async (req) => {
      const res = await next(req);
      const result = gql((req as any).operation.text);
      const fieldNames = result.definitions
        .filter((d) => (d as any).operation === "query")
        .flatMap((d) =>
          (d as any).selectionSet.selections.map((s: any) => s.name.value)
        );
      fieldNames.forEach((fieldName) => {
        const storageKey = getStableStorageKey(fieldName, (req as any).variables);
        const existingSet = keys.get(fieldName);
        if (existingSet) {
          existingSet.add(storageKey);
        } else {
          keys.set(fieldName, new Set([storageKey]));
        }
      });

      return res;
    },
  ],
  { noThrow: true }
);
export default network;
export { keys };
