import type {
  CreateContextAction,
  IFilterSystemStore,
} from "../shared/filterSystem.types";

export const CreateContextReducer = (
  state: IFilterSystemStore,
  action: CreateContextAction
) => {
  const { payload: NewContext } = action;
  if (!state.contexts.some((context) => context.name === NewContext.name)) {
    state.contexts.push(NewContext);
  }
  return state;
};
