import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";
import React, { Ref } from "react";

export interface IOrbitIconProps extends Omit<IconComponentProps, "size"> {
  ref?: Ref<HTMLSpanElement>;
  icon: IconComponentProps["component"];
  color?: string;
  alt?: string;
  size?: number | string;
  style?: React.CSSProperties;
  stroke?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const OrbitIcon = (props: IOrbitIconProps) => {
  const { icon, alt, color, style, size, ...other } = props;
  return (
    <Icon
      component={icon}
      alt={alt}
      style={{ color: color, fontSize: size, ...style }}
      {...other}
    />
  );
};
