import dayjs from "dayjs";
import { createTransform } from "redux-persist";

import { AllLocationsValue } from "../components/location-filter/locationFilter.types";
import { IDateRangeObject } from "../components/relative-date-selector/RelativeDateSelector.types";
import { IFilterSystemStore } from "../shared/filterSystem.types";

interface ISerializedDateRange extends Omit<IDateRangeObject, "dateRange"> {
  dateRange: [string, string];
}

interface ISerializedValues extends Omit<IFilterSystemStore["values"], "dateRange"> {
  dateRange: ISerializedDateRange;
}

const SetTransform = createTransform<
  IFilterSystemStore["values"],
  ISerializedValues
>(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    // convert dateRange to an Array of strings.
    const serializedState: ISerializedValues = {
      ...inboundState,
      dateRange: {
        ...inboundState.dateRange,
        dateRange: [
          inboundState.dateRange.dateRange[0].toJSON(),
          inboundState.dateRange.dateRange[1].toJSON(),
        ],
      },
    };
    return serializedState;
  },
  // transform state being rehydrated
  (outboundState) => {
    // convert dateRange back to an Array of dayjs instances.
    const serializedState: IFilterSystemStore["values"] = {
      ...outboundState,
      dateRange: {
        ...outboundState.dateRange,
        dateRange: [
          dayjs(outboundState.dateRange.dateRange[0]),
          dayjs(outboundState.dateRange.dateRange[1]),
        ],
      },
      selectedLocation: {
        ...outboundState.selectedLocation,
        label: outboundState.selectedLocation?.label || "",
        value: String(outboundState.selectedLocation?.value || AllLocationsValue),
      },
    };
    return serializedState;
  },
  // define which reducers this transform gets called for.
  { whitelist: ["values"] }
);

export default SetTransform;
