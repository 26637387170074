export const UsersManagementRoutes = {
  path: "users-management",
  name: "Users Management",
};

export enum eUserRole {
  Admin = "Admin",
  Member = "Member",
}

export enum eUserAccessTypes {
  General = "General",
  Custom = "Custom",
  FullAccess = "FullAccess",
}

export interface IUsersManagementStore {}

export interface IUserCompany {
  companyId: string;
  logoUrl?: string;
  name: string;
  userRoleForCompany?: eUserRole;
  accessType?: Array<IUserAccess>;
}

export interface IUserAccess {
  accessId?: string;
  name: string;
  type: keyof typeof eUserAccessTypes;
}

export type IUsersManagementUsersSectionSorterColumns =
  | "LASTNAME"
  | "COMPANY_NAME"
  | "USER_ROLE_FOR_COMPANY";
export const USERS_SECTION_MAPPED_COLUMNS_SORT_KEY: {
  [key: string]: IUsersManagementUsersSectionSorterColumns;
} = {
  name: "LASTNAME",
  companyName: "COMPANY_NAME",
  userRoleForCompany: "USER_ROLE_FOR_COMPANY",
};

export type IUsersManagementInvitationsSectionSorterColumns =
  | "NAME"
  | "STATUS"
  | "COMPANY_NAME"
  | "USER_ROLE_FOR_COMPANY";

export const INVITATIONS_SECTIONS_MAPPED_COLUMNS_SORT_KEY: {
  [key: string]: IUsersManagementInvitationsSectionSorterColumns;
} = {
  name: "NAME",
  status: "STATUS",
  companyName: "COMPANY_NAME",
  companyRole: "USER_ROLE_FOR_COMPANY",
};

export interface IUser {
  firstName: string;
  lastName: string;
  email?: string;
}
