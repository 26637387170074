import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router";

interface IProtectedRoute {
  shouldRedirect: () => boolean;
  redirectPath: string;
}

export const ProtectedRoute = (props: PropsWithChildren<IProtectedRoute>) => {
  if (props.shouldRedirect()) {
    return <Navigate to={props.redirectPath} replace />;
  } else {
    return <>{props.children}</>;
  }
};
