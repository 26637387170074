import { CrossIcon } from "components/orbit-icon/icons-components/CrossIcon";
import { Colors } from "style.tokens";
import { styled } from "styled-components";

import { _OrbitButton } from "../OrbitButton";

interface IOrbitCloseButtonProps {
  size: number;
}

export const _CloseButton = (props: IOrbitCloseButtonProps) => {
  return (
    <StyledButton size={props.size} showShadow>
      <CrossIcon size={24} color={Colors.gray500} />
    </StyledButton>
  );
};

const StyledButton = styled(_OrbitButton)<IOrbitCloseButtonProps>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &.ant-btn-round {
    border-radius: 50%;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .anticon {
    height: 24px;
  }
`;
