import createSagaMonitor from "@clarketm/saga-monitor";
import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "modules/auth/shared/auth.reducer";
import { filterSystemReducer } from "modules/filter-system/shared/filterSystem.reducer";
import { bookingsReducer } from "modules/home/modules/bookings/shared/bookings.reducer";
import { overviewReducer } from "modules/home/modules/overview/shared/overview.reducer";
import { usersManagementReducer } from "modules/home/modules/users-management/shared/usersManagement.reducer";
import { visitorsReducer } from "modules/home/modules/visitors/shared/visitors.reducer";
import { menuReducer } from "modules/menu/shared/menu.reducer";
import { permissionsReducer } from "modules/permissions/shared/permissions.reducer";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

// saga monitor configuration
const sagaMonitorConfig = {
  level: "debug",
  color: "#03A9F4",
  verbose: true,
  rootSagaStart: true,
  effectTrigger: true,
  effectResolve: true,
  effectReject: true,
  effectCancel: true,
  actionDispatch: true,
};

const monitor = createSagaMonitor(sagaMonitorConfig);

export const sagaMiddleware = createSagaMiddleware({ sagaMonitor: monitor });

const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    permissions: permissionsReducer,
    filterSystem: filterSystemReducer,
    overview: overviewReducer,
    visitors: visitorsReducer,
    bookings: bookingsReducer,
    usersManagement: usersManagementReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
