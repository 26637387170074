import "dayjs/locale/en";
import "dayjs/locale/nb";

import { eLanguages, Languages } from "config";
import dayjs, { type Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import weekday from "dayjs/plugin/weekday";
import { EnumToValuesArray } from "utils/enumUtils";

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(weekday);

EnumToValuesArray(eLanguages).forEach((lang) => {
  (dayjs as any).updateLocale(Languages[lang].datesLangKey, {
    weekStart: 1,
  });
});

(dayjs as any).updateLocale("nb", {
  relativeTime: {
    future: "om %s",
    past: "%s siden",
    s: "noen få sekunder",
    m: "ett minutt",
    mm: "%d minutter",
    h: "en time",
    hh: "%d timer",
    d: "en dag",
    dd: "%d dager",
    M: "en måned",
    MM: "%d måneder",
    y: "et år",
    yy: "%d år",
  },
});

export { Dayjs };
export default dayjs;
