import { StoredItems } from "../auth.types";

export const readSession = (): {
  accessToken: string | null;
  tokenExpirationAt: string | null;
  refreshToken: string | null;
} => {
  const accessToken = localStorage.getItem(StoredItems.ACCESS_TOKEN);
  const tokenExpirationAt = localStorage.getItem(
    StoredItems.ACCESS_TOKEN_EXPIRATION_DATE
  );
  const refreshToken = localStorage.getItem(StoredItems.REFRESH_TOKEN);

  if (tokenExpirationAt) {
    const expirationAtDate = new Date(Number(tokenExpirationAt) * 1000);
    if (new Date().getTime() > expirationAtDate.getTime()) {
      localStorage.removeItem(StoredItems.ACCESS_TOKEN);
      localStorage.removeItem(StoredItems.ACCESS_TOKEN_EXPIRATION_DATE);
    }
  }

  return {
    accessToken,
    tokenExpirationAt,
    refreshToken,
  };
};
