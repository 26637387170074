import { isDebug } from "utils/isDebug";

import {
  IPermissionsStore,
  RegisterPermissionAction,
} from "../shared/permissions.types";

export const registerPermissionReducer = (
  state: IPermissionsStore,
  action: RegisterPermissionAction
) => {
  if (
    !state.permissions.find((permission) => permission.name === action.payload.name)
  ) {
    state.permissions.push(action.payload);
  } else {
    isDebug() &&
      console.warn("Permission already exists in the store", action.payload);
  }
  return state;
};
