import { createAction } from "@reduxjs/toolkit";

import {
  Actions,
  type Context,
  type IContext,
  type ISetValueForAllPayload,
} from "./filterSystem.types";

export const filterSystemSetValueForAllAction = createAction<ISetValueForAllPayload>(
  Actions.FILTER_SYSTEM_SET_VALUE_FOR_ALL
);
export const filterSystemCreateContextAction = createAction<IContext>(
  Actions.FILTER_SYSTEM_CREATE_CONTEXT
);
export const filterSystemChangeCurrentContextAction = createAction<Context>(
  Actions.FILTER_SYSTEM_CHANGE_CURRENT_CONTEXT
);
