import { Space } from "antd";
import { OrbitBody } from "components/orbit-body/OrbitBody";
import { LoadingIcon } from "components/orbit-icon/icons-components/LoadingIcon";
import { TimeIcon } from "components/orbit-icon/icons-components/TimeIcon";
import { ErrorBoundary } from "modules/error-reporting/shared/components/ErrorBoundary";
import ErrorScreen from "modules/error-reporting/shared/components/ErrorScreen";
import { menuItemRegisterAction } from "modules/menu/shared/menu.actions";
import React, { Suspense } from "react";
import store from "store";
import type { IModuleConfiguration, OrbitRoute } from "types";

import { BookingsRoutes } from "./bookings.types";

const VisitorsContainerLazy = React.lazy(
  () => import("../containers/BookingsContainer")
);

const bookingsRoutes: OrbitRoute[] = [
  {
    path: BookingsRoutes.path,
    name: BookingsRoutes.name,
    element: (
      <ErrorBoundary FallbackComponent={() => <ErrorScreen />}>
        <Suspense
          fallback={
            <OrbitBody>
              <Space>
                <LoadingIcon />
                Loading bookings page
              </Space>
            </OrbitBody>
          }
        >
          <VisitorsContainerLazy />
        </Suspense>
      </ErrorBoundary>
    ),
  },
];

store.dispatch(
  menuItemRegisterAction({
    key: "bookings",
    nameTranslationKey: "onboarding:routes.bookings",
    path: BookingsRoutes.path,
    icon: <TimeIcon size={24} />,
  })
);

// Module configuration based on IModuleConfiguration interface
export const ModuleConfiguration: IModuleConfiguration = {
  name: "Bookings",
  navigation: {
    nameTranslationKey: "onboarding:routes.bookings",
    hideFromNavigation: false,
  },
  private: true,
  routes: bookingsRoutes,
};

export default ModuleConfiguration;
