import { Empty, EmptyProps, Space } from "antd";
import OrbitText from "components/orbit-text/OrbitText";
import React, { cloneElement } from "react";
import { Colors } from "style.tokens";
import styled from "styled-components";

export enum eAlign {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export interface IOrbitEmptyProps
  extends Omit<EmptyProps, "image" | "imageStyle" | "description"> {
  image?: React.JSX.Element;
  label?: string;
  sublabel?: string;
  size?: number;
  align?: eAlign;
  center?: boolean;
}

export const OrbitEmpty = (props: IOrbitEmptyProps) => {
  const { image, label, sublabel, size, align = eAlign.VERTICAL, ...rest } = props;
  return (
    <StyledEmpty
      className={align}
      image={
        image
          ? cloneElement(image, { size: size || 24, color: Colors.gray500 })
          : Empty.PRESENTED_IMAGE_SIMPLE
      }
      imageStyle={{
        marginRight: align === eAlign.HORIZONTAL ? 20 : "inherit",
        height: size ? size : image ? 24 : 30,
      }}
      description={
        <Space direction="vertical">
          {label && <OrbitText.Subtitle1>{label}</OrbitText.Subtitle1>}
          {sublabel && <StyledSublabel>{sublabel}</StyledSublabel>}
        </Space>
      }
      $center={props.center ?? false}
      {...rest}
    />
  );
};

const StyledEmpty = styled(Empty)<{ $center: boolean; $minHeight?: number }>`
  display: flex;
  min-height: 200px;

  &.${eAlign.VERTICAL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &.${eAlign.HORIZONTAL} {
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: ${({ $center }) => ($center ? "center" : "flex-start")};
  }
`;

const StyledSublabel = styled(OrbitText.Subtitle1)`
  color: var(--secondary-text-color);
`;
