import { InputToken } from "antd/es/input/style";
import { ThemeConfig } from "antd/lib/config-provider/context";
import { ComponentToken, PickerPanelToken } from "antd/lib/date-picker/style";
import { TableToken } from "antd/lib/table/style";
import { Colors, StyleTokens } from "style.tokens";

export const AntdTokens: ThemeConfig["token"] = {
  colorBgContainer: StyleTokens.backgroundColor,
  colorPrimary: StyleTokens.primaryColor,
  colorBgLayout: StyleTokens.backgroundColor,
  borderRadius: StyleTokens.borderRadius,
  controlOutlineWidth: 0,
  colorText: StyleTokens.mainTextColor,
  colorBgBase: "white",
  colorBorder: "transparent",
  colorPrimaryBorderHover: "transparent",
  lineType: "solid",
  lineWidth: 0,
  controlHeight: 40,
  lineHeight: 1.2,
  linkHoverDecoration: `underline`,
  fontSize: StyleTokens.fontSizeBase,
  fontSizeHeading1: 32,
  colorLink: StyleTokens.mainTextColor,
  colorLinkHover: StyleTokens.mainTextColor,
  colorTextPlaceholder: Colors.gray500,
};

export const AntdComponentsTokens: ThemeConfig["components"] = {
  Layout: {
    colorBgBody: StyleTokens.backgroundColor,
    colorBgBase: StyleTokens.backgroundColor,
    colorBgLayout: StyleTokens.backgroundColor,
    colorBgContainer: "white",
    colorBgHeader: StyleTokens.backgroundColor,
  },
  Menu: {
    itemActiveBg: "transparent",
    itemColor: Colors.gray500,
    itemHoverColor: Colors.gray500,
    itemSelectedBg: "transparent",
    itemHoverBg: "transparent",
    itemBg: Colors.white,
    itemSelectedColor: StyleTokens.primaryColor,
    itemMarginInline: 0,
    menuItemPaddingInline: 24,
  } as any,
  Select: {
    controlItemBgActive: "transparent",
    colorBgContainer: Colors.white,
    controlItemBgHover: Colors.green50,
  },
  Button: {
    colorPrimaryHover: Colors.gray700,
    colorBorder: AntdTokens.colorPrimary,
    colorBgContainerDisabled: Colors.gray200,
    colorTextDisabled: Colors.gray500,
    opacityLoading: 0.5,
    lineWidth: 1.5,
    boxShadow: "none",
    colorBgContainer: Colors.white,
    fontSize: StyleTokens.buttonFontSize,
  },
  Typography: {
    fontSize: StyleTokens.fontSizeBase,
  },
  Dropdown: {
    controlItemBgHover: Colors.green50,
  },
  Table: {
    colorTextHeading: Colors.gray500,
    colorBgContainer: Colors.white,
    colorBgBase: Colors.white,
    colorBgLayout: Colors.white,
    colorBgSpotlight: Colors.white,
    colorBorder: Colors.black,
    paddingXS: 12,
    lineWidth: 1,
  } as TableToken,
  Pagination: {
    itemActiveBg: Colors.white,
  },
  Switch: {
    colorPrimary: Colors.green600,
    colorPrimaryHover: Colors.green600,
  },
  Radio: {
    colorPrimary: Colors.green600,
    colorBgContainer: Colors.white,
    colorBgContainerDisabled: Colors.white,
    dotColorDisabled: Colors.gray200,
    colorTextDisabled: Colors.gray500,
    colorBorder: Colors.gray500,
    lineWidth: 2,
    wireframe: true,
    dotSize: 11,
  },
  Checkbox: {
    colorPrimary: Colors.green600,
    colorBgContainer: Colors.white,
    colorBgContainerDisabled: Colors.gray200,
    colorTextDisabled: Colors.gray500,
    colorPrimaryHover: Colors.green600,
    lineWidth: 2,
    colorBorder: Colors.gray500,
  },
  Modal: {
    paddingMD: 24,
    paddingContentHorizontalLG: 24,
  },
  Input: {
    colorPrimaryHover: Colors.green600,
    lineWidth: 2,
    colorBgContainerDisabled: Colors.gray100,
  } as InputToken,
  Tabs: {
    itemSelectedColor: Colors.black,
    inkBarColor: Colors.green600,
    lineWidthBold: 2,
    horizontalItemGutter: 24,
    itemHoverColor: Colors.gray700,
    fontSize: StyleTokens.subtitle1FontSize,
  },
  Badge: {
    fontSize: StyleTokens.captionFontSize,
    colorBgContainer: Colors.black,
    colorError: Colors.green600,
  },
  DatePicker: {
    colorTextPlaceholder: Colors.gray600,
    colorPrimaryHover: Colors.green600,
    colorPrimary: Colors.green600,
    pickerPanelCellWidth: 36,
    pickerPanelCellHeight: 34,
    presetsWidth: 280,
    presetsMaxWidth: 280,
  } as Partial<ComponentToken & PickerPanelToken>,
};
