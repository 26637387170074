import { FlagIcon } from "components/orbit-icon/icons-components/FlagIcon";
import { createElement, ReactNode } from "react";

// Global project settings
export const APP_NAME = "Mission Control";
export const SUPPORT_EMAIL = "support@getorbit.com";
export const MOBILE_APP_ONBOARDING_URL = "https://link.getorbit.com/web-onboarding";
export const DESKTOP_APP_ONBOARDING_URL = "https://www.getorbit.com/download";
// Global project settings ends

// i18n configuration starts
export enum eLanguages {
  NO = "no",
  EN = "en",
}

export interface ILanguage {
  iso: eLanguages;
  label: string;
  flag: ReactNode;
  datesLangKey: string;
}

export const Languages: Record<eLanguages, ILanguage> = {
  [eLanguages.NO]: {
    iso: eLanguages.NO,
    label: "NO",
    flag: createElement(FlagIcon, { code: eLanguages.NO, size: 20 }),
    datesLangKey: "nb",
  },
  [eLanguages.EN]: {
    iso: eLanguages.EN,
    label: "US",
    flag: createElement(FlagIcon, { code: eLanguages.EN, size: 20 }),
    datesLangKey: "en",
  },
};

export enum ePackages {
  GENERIC = "generic",
  USERS = "users",
  TEAMS = "teams",
  ONBOARDING = "onboarding",
  AUTH = "auth",
  OVERVIEW = "overview",
  VISITORS = "visitors",
  BOOKINGS = "bookings",
  INVITATIONS = "invitations",
  USERS_MANAGEMENT = "users-management",
  NOT_FOUND = "notFound",
}

export const TranslationsPackages: Record<ePackages, string> = {
  [ePackages.GENERIC]: `${ePackages.GENERIC}.json`,
  [ePackages.USERS]: `${ePackages.USERS}.json`,
  [ePackages.TEAMS]: `${ePackages.TEAMS}.json`,
  [ePackages.ONBOARDING]: `${ePackages.ONBOARDING}.json`,
  [ePackages.AUTH]: `${ePackages.AUTH}.json`,
  [ePackages.OVERVIEW]: `${ePackages.OVERVIEW}.json`,
  [ePackages.VISITORS]: `${ePackages.VISITORS}.json`,
  [ePackages.BOOKINGS]: `${ePackages.BOOKINGS}.json`,
  [ePackages.INVITATIONS]: `${ePackages.INVITATIONS}.json`,
  [ePackages.USERS_MANAGEMENT]: `${ePackages.USERS_MANAGEMENT}.json`,
  [ePackages.NOT_FOUND]: `${ePackages.NOT_FOUND}.json`,
};
// i18n configuration ends

// authentication configuration
export const SignicatAllowedLanguages = ["en", "no", "sv", "da", "fi", "de", "nl"];
// authentication ends

// breakpoints configuration
export enum eBreakpoints {
  xSmall = "xs",
  small = "sm",
  medium = "md",
  large = "lg",
  xLarge = "xl",
  xxLarge = "xxl",
}

export interface IBreakpoints<T> {
  [eBreakpoints.xSmall]: T;
  [eBreakpoints.small]: T;
  [eBreakpoints.medium]: T;
  [eBreakpoints.large]: T;
  [eBreakpoints.xLarge]: T;
  [eBreakpoints.xxLarge]: T;
}

export const BreakpointsNumber = {
  [eBreakpoints.xSmall]: 0,
  [eBreakpoints.small]: 576,
  [eBreakpoints.medium]: 768,
  [eBreakpoints.large]: 992,
  [eBreakpoints.xLarge]: 1200,
  [eBreakpoints.xxLarge]: 1600,
};
export const Breakpoints: Record<string, `${number}px`> = {
  [eBreakpoints.xSmall]: `${BreakpointsNumber[eBreakpoints.xSmall]}px`,
  [eBreakpoints.small]: `${BreakpointsNumber[eBreakpoints.small]}px`,
  [eBreakpoints.medium]: `${BreakpointsNumber[eBreakpoints.medium]}px`,
  [eBreakpoints.large]: `${BreakpointsNumber[eBreakpoints.large]}px`,
  [eBreakpoints.xLarge]: `${BreakpointsNumber[eBreakpoints.xLarge]}px`,
  [eBreakpoints.xxLarge]: `${BreakpointsNumber[eBreakpoints.xxLarge]}px`,
};

export enum eEvents {
  SIGNED_IN = "Signed in",
  SIGNED_OUT = "Signed out",
  LOCATION_SELECTED = "Location selected",
  VIEW_ALL_NAVIGATION = "View all navigation",
  DRAWER_OPENED = "Drawer opened",
  BOOKING_TABLE = "Booking table",
  TIME_SELECTED = "Time selected",
  GRAPH_MODE_SELECTED = "Graph mode selected",
  LIVE_CHAT_CONVERSATION_STARTED = "Live Chat Conversation Started",
  LANGUAGE_SELECTION = "Language selection",
  TABLE_SEARCH = "Table Search",
  LOCATION_SEARCH = "Location Search",
  TOOL_TIP = "Tool Tip",
  TABLE_ADJUSTMENT = "Table adjustment",
  INSIGHT_CAPACITY = "Insight capacity",
}
