import { PropsWithChildren } from "react";

import { IAnalyticsContext } from "../analytics.types";
import { AnalyticsContext } from "../contexts/AnalyticsContext";

export const AnalyticsProvider = (props: PropsWithChildren<IAnalyticsContext>) => {
  return (
    <AnalyticsContext.Provider value={{ instance: props.instance }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};
