import { Space } from "antd";
import { OrbitBody } from "components/orbit-body/OrbitBody";
import { LoadingIcon } from "components/orbit-icon/icons-components/LoadingIcon";
import { UserIcon } from "components/orbit-icon/icons-components/UserIcon";
import { ErrorBoundary } from "modules/error-reporting/shared/components/ErrorBoundary";
import ErrorScreen from "modules/error-reporting/shared/components/ErrorScreen";
import { menuItemRegisterAction } from "modules/menu/shared/menu.actions";
import React, { Suspense } from "react";
import store from "store";
import type { IModuleConfiguration, OrbitRoute } from "types";

import { VisitorsRoutes } from "./visitors.types";

const VisitorsContainerLazy = React.lazy(
  () => import("../containers/VisitorsContainer")
);

const visitorsRoutes: OrbitRoute[] = [
  {
    path: VisitorsRoutes.path,
    name: VisitorsRoutes.name,
    element: (
      <ErrorBoundary FallbackComponent={() => <ErrorScreen />}>
        <Suspense
          fallback={
            <OrbitBody>
              <Space>
                <LoadingIcon />
                Loading visitors page
              </Space>
            </OrbitBody>
          }
        >
          <VisitorsContainerLazy />
        </Suspense>
      </ErrorBoundary>
    ),
  },
];

store.dispatch(
  menuItemRegisterAction({
    key: "visitors",
    nameTranslationKey: "onboarding:routes.visitors",
    path: VisitorsRoutes.path,
    icon: <UserIcon size={24} />,
  })
);

// Module configuration based on IModuleConfiguration interface
export const ModuleConfiguration: IModuleConfiguration = {
  name: "Visitors",
  navigation: {
    nameTranslationKey: "onboarding:routes.visitors",
    hideFromNavigation: false,
  },
  private: true,
  routes: visitorsRoutes,
};

export default ModuleConfiguration;
