// globalStyles.js
import { BreakpointsNumber } from "config";
import dayjs from "orbitDayjs";
import { BreakpointTokens, Colors, StyleTokens } from "style.tokens";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
.ant-modal {
  @media(max-width: ${BreakpointsNumber[BreakpointTokens.mobileMaxBreakpoint]}px) {
    height: auto;
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
    border-radius: 0;
    padding-bottom: 0;

    .ant-modal-content {
      height: auto;
      min-height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0;
      border-radius: 0;

      .ant-modal-body {
        height: auto;
      }
    }
  }
  
  .ant-modal-close {
    width: 32px;

    &:hover {
      background-color: unset;
    }
  }
  .ant-modal-confirm-body {
    display: block;
    margin-top: 40px;
    margin-bottom: 32px;
    
    .anticon-exclamation-circle, .anticon-info-circle {
      display: none;
    }
  }
}
ol.toaster {
  &[data-sonner-toaster] {
    width: 280px;
  }
  li {
    transition: transform .4s,opacity .4s,height .4s,box-shadow .2s, background-color .3s;
    &[data-sonner-toast] {
      &[data-mounted=true] {
        border-radius: 14px;
        width: 280px;
        &[data-expanded=true] {
          height: auto;
          border-radius: 14px;
          width: 280px;
          height: unset;
        }
        &[data-expanded=false] {
          &[data-front=false] {
            height: unset;
          }
        }
        &[data-styled=true] {
          padding: 11px 14px;
          border: none;
        }
      }
      [data-title] {
        font-size: ${StyleTokens.subtitle2FontSize}px;
        font-weight: ${StyleTokens.subtitle2FontWeight};
      }
      [data-icon] {
        margin-left: 0px;
        margin-right: 0px;
      }
      &[data-styled=true] {
        padding: 11px 14px;
      }
    }
    &[data-type="success"] {
      background-color: ${Colors.green600};
      color: ${Colors.white};
    }
    &[data-type="error"] {
      background-color: ${Colors.red400};
      color: ${Colors.white};
    }
  }
}
  .ant-dropdown {
    &.dropdown-limited-height {
      .ant-dropdown-menu {
        max-height: 256px;
        overflow-y: scroll;
      }
    }
  }
  .ant-select-dropdown {
    .ant-space {
      display: flex;
    }
    .ant-select-item-option-content {
      padding-right: 5px;
    }
    .ant-select-item-option-selected {
        .ant-typography {
            font-size: ${StyleTokens.subtitle2FontSize}px;
            font-weight: ${StyleTokens.subtitle2FontWeight};
        }
    }
  }
  .ant-picker-dropdown {
    .ant-picker-panel-container {
      border: 2px solid ${Colors.green600};
      .ant-picker-date-panel, .ant-picker-month-panel, .ant-picker-year-panel {
        width: 280px;
        padding: 16px;
        .ant-picker-header {
          button {
            line-height: 25px;
          }
          .ant-picker-header-view {
            line-height: 25px;
          }
        }
        .ant-picker-body {
          padding: 12px 0px 0px 0px;
        }
        .ant-picker-content {
          height: 212px;
        }
      }
      .ant-picker-month-panel, .ant-picker-year-panel {
        .ant-picker-cell {
          padding: 0px;
          .ant-picker-cell-inner {
            border: 2px solid transparent;
            padding: 0px;
            height: 52px;
            line-height: 52px;
            width: 82px;
          }
          &:hover {
            &:not(.ant-picker-cell-selected) {
              &:not(.ant-picker-cell-range-start) {
                &:not(.ant-picker-cell-range-end) {
                  &:not(.ant-picker-cell-range-hover-start) {
                    &:not(.ant-picker-cell-range-hover-end) {
                      .ant-picker-cell-inner {
                        background-color: ${Colors.white};
                        border: 2px solid ${Colors.green600};
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }
          }
          &.ant-picker-cell-in-view {
            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                font-weight: bold;
                color: ${Colors.black};
              }
            }
          }
        }
      }
      .ant-picker-date-panel {
        .ant-picker-header {
          button {
            line-height: 25px;
          }
          .ant-picker-header-view {
            line-height: 25px;
          }
        }
        .ant-picker-body {
          padding: 12px 0px 0px 0px;
        }
        .ant-picker-content {
          width: auto;
          th {
            visibility: hidden;
            height: 35px;
            &:before {
              visibility: visible;
              display: block;
              position: absolute;
              width: 32px;
              /* height: 35px; */
              border: none;
              font-size: ${StyleTokens.captionFontSize}px;
              font-weight: ${StyleTokens.captionFontWeight};
              color: ${Colors.gray500}
            }
            &:nth-child(1) {
              &:before {
                content: "${dayjs().weekday(0).format("ddd")}";
              }
            }
            &:nth-child(2) {
              &:before {
                content: "${dayjs().weekday(1).format("ddd")}";
              }
            }
            &:nth-child(3) {
              &:before {
                content: "${dayjs().weekday(2).format("ddd")}";
              }
            }
            &:nth-child(4) {
              &:before {
                content: "${dayjs().weekday(3).format("ddd")}";
              }
            }
            &:nth-child(5) {
              &:before {
                content: "${dayjs().weekday(4).format("ddd")}";
              }
            }
            &:nth-child(6) {
              &:before {
                content: "${dayjs().weekday(5).format("ddd")}";
              }
            }
            &:nth-child(7) {
              &:before {
                content: "${dayjs().weekday(6).format("ddd")}";
              }
            }
          }
          .ant-picker-cell {
            padding: 0px;
            .ant-picker-cell-inner {
              border: 2px solid transparent;
              line-height: 31px;
              border-radius: 50%;
              height: 35px;
              width: 35px;
            }
            &.ant-picker-cell-today {
                .ant-picker-cell-inner {
                    border: 2px solid ${Colors.green600};
                    font-weight: bold;
                }
            }
            &.ant-picker-cell-in-view {
              &.ant-picker-cell-selected {
                .ant-picker-cell-inner {
                  font-weight: bold;
                  color: ${Colors.black};
                }
              }
            }
            &:hover {
              &:not(.ant-picker-cell-selected) {
                &:not(.ant-picker-cell-range-start) {
                  &:not(.ant-picker-cell-range-end) {
                    &:not(.ant-picker-cell-range-hover-start) {
                      &:not(.ant-picker-cell-range-hover-end) {
                        .ant-picker-cell-inner {
                          background-color: ${Colors.gray100};
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
          display: none;
        }
      }
      .ant-picker-footer {
          border-top: 1px solid ${Colors.gray200};
        .ant-picker-today-btn {
            color: ${Colors.green600};
            font-weight: bold;
        }
    }
    }
  }
`;

export default GlobalStyle;
