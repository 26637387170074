/* eslint-disable react-hooks/rules-of-hooks */
import { Breakpoints, eBreakpoints } from "config";
import { useEffect } from "react";
import { useMediaQuery } from "styled-breakpoints/use-media-query";
import { useTheme } from "styled-components";

export const useBreakpointsDebugger = () => {
  const xSmall = useMediaQuery(useTheme()?.breakpoints.down(eBreakpoints.small));
  const small = useMediaQuery(
    useTheme()?.breakpoints.between(eBreakpoints.small, eBreakpoints.medium)
  );
  const medium = useMediaQuery(
    useTheme()?.breakpoints.between(eBreakpoints.medium, eBreakpoints.large)
  );
  const large = useMediaQuery(
    useTheme()?.breakpoints.between(eBreakpoints.large, eBreakpoints.xLarge)
  );
  const xLarge = useMediaQuery(
    useTheme()?.breakpoints.between(eBreakpoints.large, eBreakpoints.xLarge)
  );
  const xxLarge = useMediaQuery(useTheme()?.breakpoints.up(eBreakpoints.xxLarge));

  useEffect(() => {
    xSmall &&
      console.log(`Breakpoints: xSmall (<${Breakpoints[eBreakpoints.small]})`);
  }, [xSmall]);
  useEffect(() => {
    small &&
      console.log(
        `Breakpoints: small (${Breakpoints[eBreakpoints.small]} < x < ${
          Breakpoints[eBreakpoints.medium]
        })`
      );
  }, [small]);
  useEffect(() => {
    medium &&
      console.log(
        `Breakpoints: medium (${Breakpoints[eBreakpoints.medium]} < x < ${
          Breakpoints[eBreakpoints.large]
        })`
      );
  }, [medium]);
  useEffect(() => {
    large &&
      console.log(
        `Breakpoints: large (${Breakpoints[eBreakpoints.large]} < x < ${
          Breakpoints[eBreakpoints.xLarge]
        })`
      );
  }, [large]);
  useEffect(() => {
    xLarge &&
      console.log(
        `Breakpoints: xLarge (${Breakpoints[eBreakpoints.xLarge]} < x < ${
          Breakpoints[eBreakpoints.xxLarge]
        })`
      );
  }, [xLarge]);
  useEffect(() => {
    xxLarge &&
      console.log(`Breakpoints: xxLarge (>${Breakpoints[eBreakpoints.xxLarge]})`);
  }, [xxLarge]);
};

/**
 * xSmall = down(small)
 * small = between(small, medium)
 * medium = between(medium, large)
 * large = between(large, xLarge)
 * xLarge = between(xLarge, xxLarge)
 * xxLarge = up(xxLarge)
 *
 *
 *
 *  |   xSmall   |   small   |   medium   |   large   |   xLarge   |   xxLarge   |
 * <------------------------------------------------------------------------------>
 *               |           |            |           |            |
 *               v           v            v           v            v
 *             small      medium        large      xLarge        xxLarge
 */
