import { ImageProps } from "antd";
import { eLanguages } from "config";

import { NorwayFlagIcon } from "./NorwayFlagIcon";
import { UnitedStatesFlagIcon } from "./UnitedStatesFlagIcon";

interface IFlagIconProps extends ImageProps {
  code: eLanguages;
  size?: number;
}
export const FlagIcon = (props: IFlagIconProps) => {
  const { code, size, ...rest } = props;
  switch (props.code) {
    case eLanguages.EN:
      return <UnitedStatesFlagIcon {...rest} width={size} height="auto" />;
    case eLanguages.NO:
      return <NorwayFlagIcon {...rest} width={size} height="auto" />;
  }
};
