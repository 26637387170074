import { createSlice } from "@reduxjs/toolkit";

import { UpdateRefreshTimeReducer } from "../reducers/updateRefreshTime.reducer";
import { visitorsUpdateRefreshTime } from "./visitors.actions";
import { IVisitorsStore } from "./visitors.types";

const initialState: () => IVisitorsStore = () => {
  return {
    refreshTime: null,
  };
};

const visitorsSlice = createSlice({
  name: "visitors",
  initialState,
  reducers: {},
  extraReducers: {
    [visitorsUpdateRefreshTime.type]: UpdateRefreshTimeReducer,
  },
});

export const visitorsReducer = visitorsSlice.reducer;
