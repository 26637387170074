import { StoredItems } from "../auth.types";

export const storeSession = (
  accessToken: string,
  tokenExpiresAt: number,
  refreshToken: string
) => {
  localStorage.setItem(StoredItems.ACCESS_TOKEN, accessToken);
  localStorage.setItem(
    StoredItems.ACCESS_TOKEN_EXPIRATION_DATE,
    String(tokenExpiresAt)
  );
  localStorage.setItem(StoredItems.REFRESH_TOKEN, refreshToken);
};
