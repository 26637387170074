// eslint-disable-next-line simple-import-sort/imports
import React from "react";
import { Navigate } from "react-router";
import { IModuleConfiguration, OrbitRoute } from "types";

import { AuthorizedRoute } from "../../auth/shared/components/AuthorizedRoute";
import overviewModule from "../modules/overview/shared/config";
import visitorsModule from "../modules/visitors/shared/config";
import bookingsModule from "../modules/bookings/shared/config";
import usersManagementModule from "../modules/users-management/shared/config";
import notFoundModule from "../../not-found/shared/config";
import { MainRoutes } from "./home.types";

const MainLayoutContainerLazy = React.lazy(
  () => import("../containers/MainLayoutContainer")
);

const mainRoutes: OrbitRoute[] = [
  {
    path: MainRoutes.path,
    name: MainRoutes.name,
    element: (
      <AuthorizedRoute>
        <MainLayoutContainerLazy />
      </AuthorizedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={MainRoutes.overview.path} />,
      },
      ...overviewModule.routes,
      ...visitorsModule.routes,
      ...bookingsModule.routes,
      ...usersManagementModule.routes,
      ...notFoundModule.routes,
    ],
  },
];

export const ModuleConfiguration: IModuleConfiguration = {
  name: "Main",
  navigation: {
    nameTranslationKey: "onboarding:routes.root",
    hideFromNavigation: false,
  },
  private: false,
  routes: mainRoutes,
};

export default ModuleConfiguration;
