import { Typography } from "antd";
import { Colors, StyleTokens } from "style.tokens";
import { css, styled } from "styled-components";

import { type IOrbitTextBaseProps } from "./OrbitText.types";
import { createOrbitTextAlternative } from "./OrbitText.utils";

const { Text } = Typography;

const OrbitText = (props: IOrbitTextBaseProps) => {
  const { weak, lineHeight, fontSize, fontWeight, color, className, ...rest } =
    props;
  return (
    <StyledText
      $fontSize={fontSize}
      $lineHeight={lineHeight}
      $fontWeight={fontWeight}
      $color={color}
      className={`${className} ${weak ? "weak" : ""}`}
      {...rest}
    />
  );
};

const StyledText = styled(Text)<
  Omit<IOrbitTextBaseProps, "weak" | "lineHeight" | "fontSize" | "fontWeight"> & {
    $lineHeight: IOrbitTextBaseProps["lineHeight"];
    $fontSize: IOrbitTextBaseProps["fontSize"];
    $fontWeight: IOrbitTextBaseProps["fontWeight"];
    $color: IOrbitTextBaseProps["color"];
  }
>`
  transition:
    all 0.2s ease-in-out,
    color 0s 0s;
  ${(props) => css`
    font-size: ${props.$fontSize}px;
    line-height: ${props.$lineHeight}px;
    font-weight: ${props.$fontWeight};
    color: ${props.$color};
  `}
  &.weak {
    color: ${Colors.gray500};
  }
`;

OrbitText.H1 = createOrbitTextAlternative({
  fontSize: StyleTokens.h1FontSize,
  lineHeight: StyleTokens.h1LineHeight,
  fontWeight: StyleTokens.h1FontWeight,
});
OrbitText.H2 = createOrbitTextAlternative({
  fontSize: StyleTokens.h2FontSize,
  lineHeight: StyleTokens.h2LineHeight,
  fontWeight: StyleTokens.h2FontWeight,
});
OrbitText.H3 = createOrbitTextAlternative({
  fontSize: StyleTokens.h3FontSize,
  lineHeight: StyleTokens.h3LineHeight,
  fontWeight: StyleTokens.h3FontWeight,
});
OrbitText.H4 = createOrbitTextAlternative({
  fontSize: StyleTokens.h4FontSize,
  lineHeight: StyleTokens.h4LineHeight,
  fontWeight: StyleTokens.h4FontWeight,
});
OrbitText.H5 = createOrbitTextAlternative({
  fontSize: StyleTokens.h5FontSize,
  lineHeight: StyleTokens.h5LineHeight,
  fontWeight: StyleTokens.h5FontWeight,
});
OrbitText.H6 = createOrbitTextAlternative({
  fontSize: StyleTokens.h6FontSize,
  lineHeight: StyleTokens.h6LineHeight,
  fontWeight: StyleTokens.h6FontWeight,
});
OrbitText.Subtitle1 = createOrbitTextAlternative({
  fontSize: StyleTokens.subtitle1FontSize,
  lineHeight: StyleTokens.subtitle1LineHeight,
  fontWeight: StyleTokens.subtitle1FontWeight,
});
OrbitText.Subtitle2 = createOrbitTextAlternative({
  fontSize: StyleTokens.subtitle2FontSize,
  lineHeight: StyleTokens.subtitle2LineHeight,
  fontWeight: StyleTokens.subtitle2FontWeight,
});
OrbitText.Body1 = createOrbitTextAlternative({
  fontSize: StyleTokens.body1FontSize,
  lineHeight: StyleTokens.body1LineHeight,
  fontWeight: StyleTokens.body1FontWeight,
});
OrbitText.Body2 = createOrbitTextAlternative({
  fontSize: StyleTokens.body2FontSize,
  lineHeight: StyleTokens.body2LineHeight,
  fontWeight: StyleTokens.body2FontWeight,
});
OrbitText.Button = createOrbitTextAlternative({
  fontSize: StyleTokens.buttonFontSize,
  lineHeight: StyleTokens.buttonLineHeight,
  fontWeight: StyleTokens.buttonFontWeight,
});
OrbitText.Caption = createOrbitTextAlternative({
  fontSize: StyleTokens.captionFontSize,
  lineHeight: StyleTokens.captionLineHeight,
  fontWeight: StyleTokens.captionFontWeight,
});
OrbitText.Overline = createOrbitTextAlternative({
  fontSize: StyleTokens.overlineFontSize,
  lineHeight: StyleTokens.overlineLineHeight,
  fontWeight: StyleTokens.overlineFontWeight,
});

export default OrbitText;
