import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { eInsightScopes } from "../components/insight-scope-selector/InsightScopeSelector.types";
import { AllLocationsObject } from "../components/location-filter/locationFilter.types";
import { DateRanges } from "../components/relative-date-selector/RelativeDateSelector.types";
import { ChangeCurrentContextReducer } from "../reducers/changeCurrentContext.reducer";
import { CreateContextReducer } from "../reducers/createContext.reducer";
import { SetValueForAllReducer } from "../reducers/setValueForAll.reducer";
import SetTransform from "../transforms/filterSystem.transforms";
import {
  filterSystemChangeCurrentContextAction,
  filterSystemCreateContextAction,
  filterSystemSetValueForAllAction,
} from "./filterSystem.actions";
import { type IFilterSystemStore } from "./filterSystem.types";

const initialState: () => IFilterSystemStore = () => {
  return {
    currentContext: "global",
    contexts: [
      {
        name: "global",
        filters: [],
      },
    ],
    values: {
      locations: [],
      dateRange: DateRanges.Today,
      selectedLocation: AllLocationsObject,
      selectedLocationForQueries: [],
      insightScope: eInsightScopes.CORE_HOURS,
    },
  };
};

const filterSystemSlice = createSlice({
  name: "filterSystem",
  initialState,
  reducers: {},
  extraReducers: {
    [filterSystemCreateContextAction.type]: CreateContextReducer,
    [filterSystemSetValueForAllAction.type]: SetValueForAllReducer,
    [filterSystemChangeCurrentContextAction.type]: ChangeCurrentContextReducer,
  },
});

const persistConfig = {
  key: "filterSystem",
  storage,
  transforms: [SetTransform],
  blacklist: ["contexts", "currentContext"],
};

export const filterSystemReducer = persistReducer<IFilterSystemStore>(
  persistConfig,
  filterSystemSlice.reducer
);
