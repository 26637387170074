import store from "store";

import { signupContextAction } from "./permissions.actions";
import { Contexts } from "./permissions.types";

const setup = () => {
  Object.entries(Contexts).forEach((context) => {
    store.dispatch(signupContextAction(context[1]));
  });
};

export default setup;
