import { createSlice } from "@reduxjs/toolkit";

import { CollapseMenuReducer } from "../reducers/collapseMenu.reducer";
import { IsMobileReducer } from "../reducers/isMobile.reducer";
import { IsNotMobileReducer } from "../reducers/isNotMobile.reducer";
import { ItemRegisterReducer } from "../reducers/itemRegister.reducer";
import { UncollapseMenuReducer } from "../reducers/uncollapseMenu.reducer";
import { isMobile } from "../utils/isMobile";
import {
  collapseMenuAction,
  isMobileAction,
  isNotMobileAction,
  menuItemRegisterAction,
  uncollapseMenuAction,
} from "./menu.actions";
import type { IMenuStore } from "./menu.types";

const initialState: () => IMenuStore = () => {
  return {
    items: [],
    isCollapsed: isMobile(),
    isMobile: isMobile(),
  };
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: {
    [menuItemRegisterAction.type]: ItemRegisterReducer,
    [collapseMenuAction.type]: CollapseMenuReducer,
    [uncollapseMenuAction.type]: UncollapseMenuReducer,
    [isMobileAction.type]: IsMobileReducer,
    [isNotMobileAction.type]: IsNotMobileReducer,
  },
});

export const menuReducer = menuSlice.reducer;
