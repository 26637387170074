import {
  DropdownDownIcon,
  IDropdownDownIconProps,
} from "components/orbit-icon/icons-components/DropdownDownIcon";
import styled from "styled-components";

interface IDropdownArrowProps extends IDropdownDownIconProps {
  reverse?: boolean;
}

export const DropdownArrow = styled((props: IDropdownArrowProps) => {
  const { reverse, ...rest } = props;
  return <DropdownDownIcon {...rest} />;
})`
  transform: ${(props: IDropdownArrowProps) =>
    props.reverse ? `rotate(-.5turn)` : `rotate(0)`};
  transition: all 0.2s ease-in-out;
`;
