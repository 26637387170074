import { App } from "antd";
import { OrbitToaster } from "components/orbit-toaster/OrbitToaster";
import { Breakpoints } from "config";
import GlobalStyle from "globalStyles";
import i18n from "i18n";
import { PropsWithChildren, useMemo } from "react";
import { I18nextProvider } from "react-i18next";
import { createStyledBreakpointsTheme } from "styled-breakpoints";
import { ThemeProvider } from "styled-components";

const breakpointsTheme = createStyledBreakpointsTheme({ breakpoints: Breakpoints });

export const OrbitRootProvider = (
  props: PropsWithChildren<{ disablePostProcess?: boolean }>
) => {
  const customI18n = useMemo(() => {
    if (props.disablePostProcess === true) {
      const updatedI18n = i18n.cloneInstance({ postProcess: false });
      return updatedI18n;
    }
    return i18n;
  }, [props.disablePostProcess]);
  return (
    <App>
      <I18nextProvider i18n={customI18n}>
        <ThemeProvider theme={breakpointsTheme}>
          <GlobalStyle />
          {props.children}
        </ThemeProvider>
        <OrbitToaster />
      </I18nextProvider>
    </App>
  );
};
