import { Analytics } from "@segment/analytics-next";
import { useEffect, useState } from "react";
import useHotjar from "react-use-hotjar";
import { isDevelopment } from "utils/isDevelopment";

import { loadAnalytics } from "../utils/loadAnalytics";

export const useConfigureAnalytics = () => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [writeKey] = useState(process.env.REACT_APP_SEGMENT_KEY || "");
  const { initHotjar } = useHotjar();

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ENABLED === "true") {
      if (!isDevelopment) {
        if (!process.env.REACT_APP_HOTJAR_ID) throw Error("Missing hotjar id");
        const hotjarid = Number(process.env.REACT_APP_HOTJAR_ID);
        if (isNaN(hotjarid)) throw Error("Hotjar id wrong format");
        initHotjar(hotjarid, 6, false, console.info);
      }
    }
  }, [initHotjar]);

  useEffect(() => {
    const load = async () => {
      try {
        setAnalytics(await loadAnalytics());
      } catch (e) {
        console.error(e);
      }
    };
    if (process.env.REACT_APP_SEGMENT_ENABLED === "true") {
      load();
    }
  }, [writeKey]);

  return analytics;
};
