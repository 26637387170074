import { eLanguages, Languages } from "config";
import i18n from "i18n";
import dayjs from "orbitDayjs";
import { useEffect } from "react";

const setDayjsLocale = (lng: eLanguages) => {
  dayjs.locale(Languages[lng].datesLangKey);
};

export const useDayjsConfiguration = () => {
  useEffect(() => {
    dayjs.locale(Languages[i18n.language as eLanguages].datesLangKey);

    i18n.on("languageChanged", setDayjsLocale);
    return () => i18n.off("languageChanged", setDayjsLocale);
  }, []);
};
