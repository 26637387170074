import { TodayIcon } from "components/orbit-icon/icons-components/TodayIcon";
import { ErrorBoundary } from "modules/error-reporting/shared/components/ErrorBoundary";
import ErrorScreen from "modules/error-reporting/shared/components/ErrorScreen";
import { menuItemRegisterAction } from "modules/menu/shared/menu.actions";
import React from "react";
import { useQueryLoader } from "react-relay";
import store from "store";
import { IModuleConfiguration, OrbitRoute } from "types";

import OverviewContainer from "../containers/OverviewContainer";
import { OverviewQuery as IOverviewQuery } from "../queries/__generated__/OverviewQuery.graphql";
import OverviewQuery from "../queries/OverviewQuery";
import { OverviewRoutes } from "./overview.types";

const OverviewContainerLazy = React.lazy<typeof OverviewContainer>(
  () => import("../containers/OverviewContainer")
);

const OverviewEntrypoint = () => {
  const [overviewPreloadedQuery, loadOverviewQuery] =
    useQueryLoader<IOverviewQuery>(OverviewQuery);

  return (
    <OverviewContainerLazy
      queryRef={overviewPreloadedQuery}
      queryLoader={loadOverviewQuery}
    />
  );
};

const overviewRoutes: OrbitRoute[] = [
  {
    path: OverviewRoutes.path,
    name: OverviewRoutes.name,
    element: (
      <ErrorBoundary FallbackComponent={() => <ErrorScreen />}>
        <OverviewEntrypoint />
      </ErrorBoundary>
    ),
  },
];

store.dispatch(
  menuItemRegisterAction({
    key: "overview",
    nameTranslationKey: "onboarding:routes.overview",
    path: OverviewRoutes.path,
    icon: <TodayIcon size={24} />,
  })
);

// Module configuration based on IModuleConfiguration interface
export const ModuleConfiguration: IModuleConfiguration = {
  name: "Overview",
  navigation: {
    nameTranslationKey: "onboarding:routes.overview",
    hideFromNavigation: false,
  },
  private: true,
  routes: overviewRoutes,
};

export default ModuleConfiguration;
