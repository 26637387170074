import {
  IOrbitSelectProps,
  OrbitOption,
  OrbitSelect,
} from "components/orbit-select/OrbitSelect";
import { OrbitSlot } from "components/orbit-slot/OrbitSlot";
import { ILanguage } from "config";
import styled from "styled-components";

export interface ILanguageSelectorPureProps
  extends Omit<
    IOrbitSelectProps<ILanguage["iso"], ILanguage>,
    "defaultValue" | "popupMatchSelectWidth"
  > {
  className?: string;
  currentLanguage: ILanguage;
  languages: ILanguage[];
  onChange?: (newLang: string) => void;
}

export const LanguageSelectorPure = (props: ILanguageSelectorPureProps) => {
  const { currentLanguage, languages, ...other } = props;
  return (
    <OrbitSelect<ILanguage["iso"], ILanguage>
      popupMatchSelectWidth={220}
      defaultValue={currentLanguage?.iso}
      {...other}
    >
      {languages.map((lang) => (
        <OrbitOption
          data-testid="option"
          value={lang.iso}
          key={`lang-${lang.iso}`}
          label={lang.label}
        >
          <StyledSlot leftComponent={lang.flag} label={lang.label} />
        </OrbitOption>
      ))}
    </OrbitSelect>
  );
};

const StyledSlot = styled(OrbitSlot)`
  height: 100%;
  .emoji {
    margin-right: 10px;
  }

  span {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      margin-right: 5px;
    }
  }
`;
