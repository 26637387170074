import { createSlice } from "@reduxjs/toolkit";

import { IUsersManagementStore } from "./usersManagement.types";

const initialState: () => IUsersManagementStore = () => {
  return {};
};

const usersManagementSlice = createSlice({
  name: "usersManagement",
  initialState,
  reducers: {},
  extraReducers: {},
});

export const usersManagementReducer = usersManagementSlice.reducer;
