import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Space } from "antd";
import { ReactNode } from "react";
import { Colors, StyleTokens } from "style.tokens";
import styled, { css } from "styled-components";

export interface IOrbitButtonProps extends Omit<ButtonProps, "type" | "size"> {
  children?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  squared?: boolean;
  showShadow?: boolean;
  avoidClickWhenLoading?: boolean;
  type?: "default" | "primary" | "secondary" | "text";
}

export const _OrbitButton = (props: IOrbitButtonProps) => {
  const {
    children,
    leftIcon,
    rightIcon,
    squared,
    type,
    showShadow,
    loading,
    onClick,
    avoidClickWhenLoading,
    ...others
  } = props;
  return (
    <StyledButton
      size="middle"
      shape={squared ? "default" : "round"}
      type={type === "secondary" ? "default" : type}
      $orbitType={type || "default"}
      $showShadow={showShadow}
      loading={loading}
      onClick={(event) =>
        (!loading || (loading && !avoidClickWhenLoading)) &&
        onClick &&
        onClick(event)
      }
      {...others}
    >
      <Space size={8} styles={{ item: { lineHeight: "normal" } }}>
        {leftIcon}
        {loading && <Loading3QuartersOutlined spin />}
        {children}
        {rightIcon}
      </Space>
    </StyledButton>
  );
};

const StyledButton = styled(Button)<
  Omit<IOrbitButtonProps, "showShadow"> & {
    $orbitType: IOrbitButtonProps["type"];
    $showShadow: IOrbitButtonProps["showShadow"];
    $loading?: boolean;
  }
>`
  transition: 0.2s all;

  ${(props) => {
    if (props.$showShadow)
      return css`
        box-shadow: ${StyleTokens.elementShadowBox};
      `;
  }}

  ${(props) => {
    if (props.$orbitType === "text") {
      return css`
        &:disabled {
          border-color: "transparent";
        }
        &:not(:disabled) {
          color: ${Colors.black};
          &:hover {
            color: ${Colors.black};
            &:active {
            }
          }
        }
      `;
    }
    if (props.$orbitType === "default") {
      return css`
        &:disabled {
          border-color: ${Colors.gray500};
        }
        &:not(:disabled) {
          color: ${Colors.black};
          &:hover {
            background-color: ${Colors.gray200};
            color: ${Colors.black};
            &:active {
              background-color: ${Colors.gray100};
            }
          }
        }
      `;
    }
    if (props.$orbitType === "primary") {
      return css`
        min-width: 120px;
        &:disabled {
          border-color: ${Colors.gray200};
        }
        &:not(:disabled) {
          color: ${Colors.white};
          &:hover {
            color: ${Colors.white};
            &:active {
              background-color: ${Colors.gray500};
            }
          }
        }
      `;
    }
    if (props.$orbitType === "secondary") {
      return css`
        border-color: ${Colors.gray100};
        &:disabled {
          border-color: ${Colors.gray100};
        }
        &:not(:disabled) {
          color: ${Colors.black};
          background-color: ${Colors.gray100};
          border-color: ${Colors.gray100};
          &:hover {
            border-color: ${Colors.gray200};
            background-color: ${Colors.gray200};
            color: ${Colors.black};
            &:active {
              background-color: ${Colors.gray100};
              border-color: ${Colors.gray100};
            }
          }
        }
      `;
    }
  }}
`;
