import Bugsnag, { OnErrorCallback } from "@bugsnag/js";
import React, { Fragment, PropsWithChildren, useMemo } from "react";
import { IErrorBoundaryFallbackComponent } from "types";

type IErrorBoundaryProps = PropsWithChildren<{
  onError?: OnErrorCallback;
  FallbackComponent?: IErrorBoundaryFallbackComponent;
}>;

export const ErrorBoundary = (props: IErrorBoundaryProps) => {
  const ReactPlugin = useMemo(() => {
    return Bugsnag.getPlugin("react");
  }, []);

  const ErrorBoundaryComponentFromBugsnag = useMemo(() => {
    if (process.env.REACT_APP_BUGSNAG_ENABLED === "false") return Fragment;
    if (ReactPlugin === undefined) return null;
    return ReactPlugin.createErrorBoundary(React);
  }, [ReactPlugin]);

  if (!ErrorBoundaryComponentFromBugsnag) return null;

  return process.env.REACT_APP_BUGSNAG_ENABLED === "false" ? (
    props.children
  ) : (
    <ErrorBoundaryComponentFromBugsnag {...props}>
      {props.children}
    </ErrorBoundaryComponentFromBugsnag>
  );
};
