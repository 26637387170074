import { LogoLoading } from "components/orbit-animations/animation-components/LogoLoading";

export const OrbitPageLoader = () => {
  /**
   * For some reason, using styled components here
   * is breaking the OMC styles as we're using this
   * component as a Suspense fallback. Using inline
   * styles is not affecting any other styles.
   *  */
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ width: "200px" }}>
        <LogoLoading />
      </div>
    </div>
  );
};
