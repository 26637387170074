import { PropsWithChildren } from "react";

import { eFeatureFlags } from "../featureFlags.types";
import { useFeatureFlags } from "../hooks/useFeatureFlags";

export const RequireFeatureFlag = ({
  children,
  featureFlag,
  fallback,
}: PropsWithChildren<{ featureFlag: eFeatureFlags; fallback: React.ReactNode }>) => {
  const flags = useFeatureFlags();
  if (flags[featureFlag]) {
    return children;
  } else {
    return fallback;
  }
};
